import { Typography } from "@material-ui/core";
import { default as React, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import ReactPuzzleConfirm from "react-puzzle-confirm";
import i18n from "../../i18n";

const StepCaptchaVerification = forwardRef((props, ref) => {
  const { handleBack, handleNextCallback, submit } = props;

  const { t } = useTranslation("report", { i18n });

  return (
    <>
      <div>
        <ReactPuzzleConfirm
          onClose={() => {
            handleBack();
          }}
          onSuccess={() => {
            handleNextCallback(true);
            submit();
          }}
          onFail={() => console.log("failed")}
          title={t("puzzle_confirm.title")}
          sliderTitle={t("puzzle_confirm.slider_title")}
          failMessage={t("puzzle_confirm.fail_message")}
          successMessage={t("puzzle_confirm.success_message")}
          closeButtonLabel={t("puzzle_confirm.close_button_label")}
          refreshButtonLabel={t("puzzle_confirm.refresh_button_label")}
          disableRefreshIdleState={true}
        />
      </div>
      <div style={{ textAlign: "center", paddingTop: 10 }}>
        <Typography style={{ fontSize: "12px" }}>
          {t("puzzle_confirm.slider_title")}
        </Typography>
      </div>
      <div style={{ textAlign: "center" }}>
        <img
          alt="Hint"
          height="90px"
          src={process.env.PUBLIC_URL + "/assets/hint/captcha_hint.gif"}
        ></img>
      </div>
    </>
  );
});

export default StepCaptchaVerification;
