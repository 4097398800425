import ELK from "elkjs/lib/elk.bundled.js";
import React, { useEffect, useState } from "react";
import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  useEdgesState,
  useNodesState,
} from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ThingService } from "services/thing/thingService";
import { ThingRelationService } from "services/ThingRelationService";
import { ThingDesc } from "services/utils";
import i18n from "../../i18n";

export default function ThingRelationExplorer(props) {
  const { t } = useTranslation("thing", { i18n });
  const { id } = useParams();

  const [thing, setThing] = useState(null);
  const [thingRelations, setThingRelations] = useState([]);

  const accountId = localStorage.accountId;

  const [nodes, setNodes, onNodesChange] = useNodesState();
  const [edges, setEdges, onEdgesChange] = useEdgesState();

  useEffect(() => {
    if (id) {
      ThingService.findById(id, setThing, (error) => {});

      ThingRelationService.findThingRelations(
        id,
        accountId,
        findThingRelationsOnSuccess,
        findThingRelationsOnError
      );
    }
  }, [id]);

  const findThingRelationsOnSuccess = (data) => {
    setThingRelations(data);
  };

  const findThingRelationsOnError = () => {};

  useEffect(() => {
    if (!thing || !thing.id) return;

    console.log({ thing });
    console.log({ thingRelations });

    let initialNodes = [];
    let initialPositions = { x: 200, y: 30 };
    if (!!thing.id) {
      let rootNode = {
        id: thing.id.toString(),
        type: "input",
        data: { label: ThingDesc(thing) },
        position: { x: initialPositions.x + 26, y: initialPositions.y },
        style: { width: "20%", height: "20%", borderRadius: "50%" },
      };
      initialNodes.push(rootNode);
    }

    if (!!thingRelations && thingRelations.length > 0) {
      let positions = {
        x: initialPositions.x - 300,
        y: initialPositions.y + 150,
      };

      thingRelations
        .slice(0)
        .reverse()
        .map((thingRelation, index) => {
          let relatedThing =
            thingRelation.sourceThing.id === thing.id
              ? thingRelation.targetThing
              : thingRelation.sourceThing;
          positions.x = positions.x + 200;

          let node = {
            id: relatedThing.id.toString(),
            type: "default",
            data: { label: ThingDesc(relatedThing) },
            position: { x: positions.x, y: positions.y },
          };
          initialNodes.push(node);
        });
    }

    var tempInitialEdges = [];

    if (!!thingRelations && thingRelations.length > 0) {
      thingRelations.forEach((thingRelation) => {
        let edge = {
          id: thingRelation.sourceThing.id + "_" + thingRelation.targetThing.id,
          //source: thingRelation.sourceThing.id.toString(),
          //target: thingRelation.targetThing.id.toString(),
          source: thing.id.toString(),
          target:
            thingRelation.sourceThing.id === thing.id
              ? thingRelation.targetThing.id.toString()
              : thingRelation.sourceThing.id.toString(),
          label:
            thingRelation.sourceThing.id === thing.id
              ? thingRelation.relationType.nameSource
              : thingRelation.relationType.nameTarget,
          type: "smart",
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
        };

        tempInitialEdges.push(edge);
      });
    }

    const layoutedNodes = initialNodes; //getLayoutedElements(initialNodes, tempInitialEdges);

    const layoutedNodes2 = getLayoutedElements(initialNodes, tempInitialEdges);

    //console.log({ layoutedNodes2 });
    //setNodes(layoutedNodes);

    setEdges(tempInitialEdges);
    console.log({ tempInitialEdges });
  }, [thing, thingRelations]);

  const getLayoutedElements = (nodes, edges) => {
    const elk = new ELK();

    const defaultOptions = {
      "elk.algorithm": "layered",
      "elk.direction": "DOWN",
      "elk.layered.spacing.nodeNodeBetweenLayers": 200,
      "elk.spacing.nodeNode": 200,
    };

    const layoutOptions = { ...defaultOptions };
    const graph = {
      id: "root",
      layoutOptions: layoutOptions,
      children: nodes,
      edges: edges,
    };

    let result = null;
    elk.layout(graph).then(({ children }) => {
      // By mutating the children in-place we saves ourselves from creating a
      // needless copy of the nodes array.
      children.forEach((node) => {
        node.position = { x: node.x, y: node.y };
        console.log("node.position", node.position);
      });

      console.log({ children });
      result = children;
      setNodes(children);
    });

    console.log("result", result);

    return result;
  };

  return (
    <div style={{ width: "100%", height: 400 }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
      >
        <Controls />
        <Background color="#aaa" gap={16} />
      </ReactFlow>
    </div>
  );
}
