import { faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Paper } from "@material-ui/core";
import { CallMade, CallReceived } from "@material-ui/icons";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import FaButton from "react-base-fa/dist/fa/faButton";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { CustomerInteractionService } from "services/CustomerInteractionService";
import { roundNumber } from "services/utils";
import i18n from "../../i18n";
import { useStyles } from "./TotalInteractionsCountStyle";

const TotalInteractionsCount = forwardRef((props, ref) => {
  const { onClick } = props;
  const { t } = useTranslation(["customer", "components"], { i18n });

  const classes = useStyles();
  let history = useHistory();

  const [dailyCounts, setDailyCounts] = useState(null);

  const [masterSearchForm, setMasterSearchForm] = useState(null);

  const [valueTotal, setValueTotal] = useState({ value: 0, percentage: 0 });
  const [valueDaily, setValueDaily] = useState({ value: 0, percentage: 0 });
  const [valueWeekly, setValueWeekly] = useState({ value: 0, percentage: 0 });
  const [valueMonthly, setValueMonthly] = useState({ value: 0, percentage: 0 });

  useImperativeHandle(ref, () => ({
    refreshTable(searchForm) {
      if (searchForm) {
        setMasterSearchForm(searchForm);
        CustomerInteractionService.getDailyCounts(
          searchForm,
          getDailyCountsOnSuccess,
          (error) => {}
        );
      }
    },
  }));

  const sameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const getYYYYMMDD = (date) => {
    return date.getFullYear() * 10000 + date.getMonth() * 100 + date.getDate();
  };

  const calculatePercentage = (val2, val1) => {
    if (val1 && val2) return roundNumber(((val2 - val1) * 100) / val1, 1);
    return null;
  };

  const getListTotals = (list, dateStart, dateEnd) => {
    let result = null;

    if (dateEnd)
      result = list.filter(
        (x) =>
          getYYYYMMDD(new Date(x.date)) >= getYYYYMMDD(dateStart) &&
          getYYYYMMDD(new Date(x.date)) < getYYYYMMDD(dateEnd)
      );
    else
      result = list.filter(
        (x) => getYYYYMMDD(new Date(x.date)) >= getYYYYMMDD(dateStart)
      );

    return result.reduce((accumulator, item) => {
      return accumulator + item.value;
    }, 0);
  };

  const getDailyCountsOnSuccess = (data) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const today_1 = new Date(today);
    today_1.setDate(today.getDate() - 1);

    const today_7 = new Date(today);
    today_7.setDate(today.getDate() - 7);
    const today_14 = new Date(today_7);
    today_14.setDate(today_7.getDate() - 7);

    const today_30 = new Date(today);
    today_30.setDate(today.getDate() - 30);
    const today_60 = new Date(today);
    today_60.setDate(today.getDate() - 60);

    setValueTotal({
      value: data.list.reduce((accumulator, item) => {
        return accumulator + item.value;
      }, 0),
      percentage: 0,
    });

    const todayResult = data.list.find((x) => sameDay(new Date(x.date), today));
    const yesterdayResult = data.list.find((x) =>
      sameDay(new Date(x.date), today_1)
    );

    const weekValue = getListTotals(data.list, today_7);
    const preWeekValue = getListTotals(data.list, today_14, today_7);

    const monthValue = getListTotals(data.list, today_30);
    const preMonthValue = getListTotals(data.list, today_60, today_30);

    if (todayResult) {
      setValueDaily({
        value: todayResult.value,
        percentage: calculatePercentage(
          todayResult.value,
          yesterdayResult?.value
        ),
      });
    }

    setValueWeekly({
      value: weekValue,
      percentage: calculatePercentage(weekValue, preWeekValue),
    });

    setValueMonthly({
      value: monthValue,
      percentage: calculatePercentage(monthValue, preMonthValue),
    });

    setDailyCounts(data);
  };

  const goToCustomerInteractionIndoorMap = () => {
    const tmpTo = {
      pathname: `/customerInteractionIndoorMap`,
      breadCrumbKey: `/customerInteractionIndoorMap`,
      state: {
        searchForm: { ...masterSearchForm },
      },
    };
    history.push(tmpTo);
  };

  const renderComponent = (className, title, result, showPercentage) => {
    return (
      <Grid item xs={3}>
        <Paper className={className}>
          <div className={classes.title}>{title}</div>
          <div>
            <div className={classes.value}>{result.value}</div>
            <div
              className={
                result.percentage < 0
                  ? classes.percentageNegative
                  : classes.percentagePositive
              }
            >
              {showPercentage && (
                <>
                  {`${result.percentage ?? "0"}%`}
                  {result.percentage > 0 && (
                    <CallMade className={classes.percentageIcon} />
                  )}
                  {result.percentage < 0 && (
                    <CallReceived className={classes.percentageIcon} />
                  )}
                </>
              )}
              {!showPercentage && (
                <div className={classes.percentagePlaceholder} />
              )}
            </div>
          </div>
        </Paper>
      </Grid>
    );
  };

  return (
    <>
      {dailyCounts && (
        <Grid container spacing={2} style={{ overflowY: "auto" }}>
          <Grid item xs={10} data-fa-section="TOTAL_INTERACTION_COUNT">
            <Grid container direction="row" spacing={1}>
              {renderComponent(
                classes.paperTotal,
                t("total_interaction"),
                valueTotal,
                false
              )}
              {renderComponent(
                classes.paperDaily,
                t("daily_interaction"),
                valueDaily,
                true
              )}
              {renderComponent(
                classes.paperWeekly,
                t("weekly_interaction"),
                valueWeekly,
                true
              )}
              {renderComponent(
                classes.paperMonthly,
                t("monthly_interaction"),
                valueMonthly,
                true
              )}
            </Grid>
          </Grid>
          <Grid item xs={2} data-fa-button="Harita">
            <Paper>
              <FaButton
                variant="contained"
                color="primary"
                size="small"
                faClick={() => {
                  goToCustomerInteractionIndoorMap();
                }}
                startIcon={<FontAwesomeIcon icon={faMap} />}
                style={{ height: 57 }}
              >
                {t("indoor_interaction")}
              </FaButton>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
});
export default TotalInteractionsCount;
