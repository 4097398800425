import { Avatar } from "@material-ui/core";
import React from "react";
import { BASE_URL } from "serviceWorker";
import { THING } from "services/faApi";

export default function ThingTableDecorator(props) {
  const { value } = props;

  const renderThing = () => {
    if (value.thumbnailUrl != null) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="thing"
            src={
              BASE_URL +
              THING.thumbnail +
              "/" +
              value.id +
              "/" +
              value.thumbnailUrl
            }
            style={{
              width: "48px",
              height: "40px",
              marginRight: "10px",
              borderRadius: "15%",
            }}
          />{" "}
          {value.identifier}
        </div>
      );
    } else if (value.thumbnail != null)
      //return <div style={{display: "flex", alignItems: "center"}}><img src={BASE_URL + THING.getImage + "?thingId=0" + "&objectId=" + row.defaultThumbnail } style={{width: "48px", height: "40px", marginRight: "10px"}} /> {value}</div>
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            alt="thing"
            src={`data:image/png;base64, ${value.thumbnail}`}
            style={{
              width: "48px",
              height: "48px",
              marginRight: "10px",
              borderRadius: "15%",
            }}
          />{" "}
          {value.identifier}
        </div>
      );
    else
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Avatar
            variant="square"
            style={{
              width: "48px",
              height: "48px",
              marginRight: "10px",
              borderRadius: "15%",
            }}
          >
            {" "}
            {value.identifier.charAt(0)}
          </Avatar>{" "}
          {value.identifier}
        </div>
      );
  };

  return renderThing();
}
