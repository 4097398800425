import { Card, CardHeader, IconButton } from "@material-ui/core";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { GeolocateControl, Layer, Map, Source } from "react-map-gl";
import { MAPBOX_TOKEN } from "services/utils/Constants";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

import { ZoomOutMap } from "@material-ui/icons";
import CustomerInteractionMapHelp from "features/help/CustomerInteractionMapHelp";
import { useHistory } from "react-router-dom";
import { CustomerInteractionService } from "services/CustomerInteractionService";
import { ParseLocation } from "services/utils";
import {
  clusterCountLayer,
  clusterLayer,
  heatmapLayer,
  unclusteredPointLayer,
} from "../thingsMap/layers";

const CustomerInteractionMapCard = forwardRef((props, ref) => {
  const { searchForm } = props;
  const { t } = useTranslation(["customer", "schedulePlan"], { i18n });
  const labelClasses = labelStyles();
  let history = useHistory();

  const [masterSearchForm, setMasterSearchForm] = useState(null);

  const [viewport, setViewPort] = useState({
    latitude: 39.862645729977174,
    longitude: 32.738810051232576,
    zoom: 5,
    bearing: 0,
    pitch: 0,
  });

  const mapRef = useRef();

  const geolocateStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    margin: 10,
  };

  useImperativeHandle(ref, () => ({
    refreshTable(searchForm) {
      if (searchForm) {
        setMasterSearchForm(searchForm);
        const paging = { page: 0, size: 10000, isAsc: false, sort: "date" };
        CustomerInteractionService.search(
          { ...searchForm },
          paging,
          findCustomerInteractionOnSuccess,
          (error) => {}
        );
      }
    },
  }));

  const onOpenMap = (event, row) => {
    console.log({ masterSearchForm });
    const tmpTo = {
      pathname: `/customerInteractionMap`,
      breadCrumbKey: `/customerInteractionMap`,
      state: {
        searchForm: {
          ...masterSearchForm,
        },
      },
    };
    history.push(tmpTo);
  };

  const [mapType, SetMapType] = useState("numericMap");

  const [geojson, setGeojson] = useState({
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [32.685632575303316, 39.882920037461844],
        },
      },
    ],
  });

  const findCustomerInteractionOnSuccess = (data) => {
    const temp = data
      .filter((x) => x.gpsLocation || x.ipGpsLocation)
      .map((item) => {
        var location = ParseLocation(item.gpsLocation ?? item.ipGpsLocation);
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [location.lng, location.lat],
            mag: 1,
          },
          properties: {
            id: item.id,
          },
          date: item.date,
        };
      });
    const maxDate = Math.max(
      ...data.map((element) => {
        return new Date(element.date).getTime();
      })
    );

    const minDate = Math.min(
      ...data.map((element) => {
        return new Date(element.date).getTime();
      })
    );

    setGeojson({
      type: "FeatureCollection",
      features: temp,
    });
  };

  return (
    <Card
      data-fa-section="CUSTOMER_INTERACTION_MAP_CARD"
      style={{ overflowY: "auto" }}
    >
      <CardHeader
        title={t("interaction_map")}
        action={
          <>
            <CustomerInteractionMapHelp value="customer_interaction_map" />
            <IconButton
              data-fa-button="CUSTOMER_INTERACTION_MAP_ZOOM"
              onClick={() => {
                onOpenMap();
              }}
              title={t("Genişlet")}
            >
              <ZoomOutMap />
            </IconButton>
          </>
        }
      ></CardHeader>
      <div style={{ height: 400 }}>
        <Map
          {...viewport}
          width="100%"
          height="100%"
          mapStyle="mapbox://styles/mapbox/light-v10"
          onMove={(evt) => setViewPort(evt.viewState)}
          mapboxAccessToken={MAPBOX_TOKEN}
          //onClick={onClick}
          ref={mapRef}
          interactiveLayerIds={[unclusteredPointLayer.id, clusterLayer.id]}
        >
          <GeolocateControl
            style={geolocateStyle}
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
          />
          {mapType === "numericMap" && (
            <Source
              id="things"
              type="geojson"
              data={geojson}
              cluster={true}
              clusterMaxZoom={14}
              clusterRadius={50}
            >
              <Layer {...clusterLayer} />
              <Layer {...clusterCountLayer} />

              <Layer {...unclusteredPointLayer} />
            </Source>
          )}
          {mapType === "heatMap" && (
            <Source id="things" type="geojson" data={geojson}>
              <Layer {...heatmapLayer} />
            </Source>
          )}
        </Map>
      </div>
    </Card>
  );
});
export default CustomerInteractionMapCard;
