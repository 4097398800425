import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Typography } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Switch from "@material-ui/core/Switch";
import { green, grey } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/core/styles";
import { Refresh } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import PauseIcon from "@material-ui/icons/Pause";
import Adsense from "components/adsense/Adsense";
import IssueClassAutoComplete from "components/autocomplete/IssueClassAutoComplete";
import ThingAutoComplete from "components/autocomplete/ThingAutoComplete";
import UserAndGroupAutoComplete from "components/autocomplete/UserAndGroupAutoComplete";
import DateButtonGroup from "components/buttonGroup/DateButtonGroup";
import StatusButtonGroup from "components/buttonGroup/StatusButtonGroup";
import RefreshPeriodButton from "components/buttons/RefreshPeriodButton";
import DateRangeSelector from "components/dateRangeSelector";
import ThingTableDecorator from "components/decorator/ThingTableDecorator";
import UserDetail from "components/detail/UserDetail";
import ThingsDataTable from "components/thingsDataTable";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { FaButton, FaInput, FaSearch } from "react-base-fa/dist/fa";
import { useDialog } from "react-base-fa/dist/fa/faBasicDialog";
import { snackbar } from "react-base-fa/dist/fa/faSnackbar/snackbarSlice";
import FaAxios from "react-base-fa/dist/services/faAxios";
import { requests } from "react-base-fa/dist/services/requestCreater";
import {
  getGroups,
  getOrganizations,
  getRole,
  getUserInfo,
} from "react-base-fa/dist/services/sessionSlice";
import { qsParse } from "react-base-fa/dist/services/utils";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ThingService } from "services/thing/thingService";
import { calculateDate, getDateTimeWFormat, isTNF } from "services/utils";
import { ROLES } from "services/utils/Roles";
import { checkRole, checkRoleByIssue } from "services/utils/SecurityUtils";
import { labelStyles } from "styles/ThingsLabelStyle";
import LoadingBar from "../../components/loadingBar";
import i18n from "../../i18n";
import { AccPreferencesService } from "../../services/accPreferences/accPreferencesService";
import { ISSUE } from "../../services/faApi";
import { IssueDesc, smartTimeDiff } from "../../services/utils";
import IssueClose from "./IssueClose";
import IssueCreate from "./IssueCreate";
import TaskEdit from "./TaskEdit";
import { useStyles } from "./style";

export default function Issues(props) {
  const { refreshCount } = props;

  const { t } = useTranslation(["issue", "issueCloseType"], { i18n });
  const [loading, setLoading] = useState(false);
  const [isNewIssueDialog, setIsNewIssueDialog] = useState(false);
  const [isNewTaskDialog, setIsNewTaskDialog] = useState(false);
  const [isSorunKapatDialog, setIsSorunKapatDialog] = useState(false);

  const [selectedIssue, setSelectedIssue] = useState(null);
  const [accPreferences, setAccPreferences] = useState(null);
  const faDialog = useDialog();
  const [files, setFiles] = useState([]);
  const classes = useStyles();
  const accountId = localStorage.accountId;
  const tableRef = useRef();
  const homepage = window.location.pathname === "/homepage" ? true : false;
  const userRole = useSelector(getRole);
  const userGroups = useSelector(getGroups);
  const userOrganizations = useSelector(getOrganizations);
  const userInfo = useSelector(getUserInfo);
  const location = useLocation();
  const dialogRef = useRef();

  const [refreshPeriod, setRefreshPeriod] = useState(60);
  const [anchorEl, setAnchorEl] = useState(null);

  const labelClasses = labelStyles();

  const isTaskManagement = checkRole([ROLES.TASK_MANAGEMENT], userRole);

  const dispatch = useDispatch();
  const showAlert = (message, type) => {
    let snackBarObj = {
      message: message,
      type: type,
      position: {
        vertical: "top",
        horizontal: "right",
      },
    };
    dispatch(snackbar(snackBarObj));
  };
  let history = useHistory();
  let queryParams = qsParse(history.location.search);
  queryParams["accountId"] = localStorage.accountId;
  const [issueDTO, setIssueDTO] = useState({
    thing: null,
    description: "",
    user: {},
    notificationDate: "",
    status: "",
    notifier: userInfo?.fullname,
    hashtag: "",
    issueClass: {},
    noThing: false,
  });

  const newSearchForm = () => {
    let result = {
      id: null,
      notifier: null,
      issueClass: null,
      issueClassId: null,
      thing: location.state?.thing,
      thingId: location.state?.thing ? location.state.thing.id : null,
      user: null,
      userId: null,
      description: null,
      accountId: accountId,
      status: null,
      notificationDateValue: null,
      notificationDateStart: props.startDate ? props.startDate : null,
    };
    let tmp = qsParse(history.location.search);
    if (tmp && tmp.sf) {
      return { ...result, ...tmp.sf };
    }

    return result;
  };

  useEffect(() => {
    if (refreshCount > 0) {
      tableRef.current.refreshTable();
    }
  }, [refreshCount]);

  const [searchForm, setSearchForm] = useState(newSearchForm());

  const searchSubmit = () => {
    tableRef.current.search(searchForm);
  };

  const searchSummary = (summary) => {
    setSearchForm({ ...searchForm, summary: summary });
    tableRef.current.search({ ...searchForm, summary: summary });
  };

  const [searchInfo, setSearchInfo] = useState(null);

  const searchClear = () => {
    setSearchForm({
      notifier: null,
      issueClass: null,
      issueClassId: null,
      thing: null,
      thingId: null,
      user: null,
      userId: null,
      description: null,
      accountId: accountId,
      status: null,
      notificationDateValue: null,
      notificationDateStart: null,
    });
  };

  const GreenSwitch = withStyles({
    switchBase: {
      color: green[300],
      "&$checked": {
        color: grey[500],
      },
      "&$checked + $track": {
        backgroundColor: green[500],
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const columns = [
    {
      field: "id",
      label: t("ID"),
      align: "center",
    },
    {
      field: "notificationDate",
      label: t("BILDIRIM_TARIH"),
      align: "center",
      type: "smartDateTime",
      // className: classes.justBigScreens,
    },
    {
      field: "identifier",
      label: isTNF(t("THING"), t("THING_T2C")),
      align: "center",
      // inner: "description",
      valueFormatter: (value, row) => {
        return <ThingTableDecorator value={row.thing} />;
      },
    },
    {
      field: "id2",
      label: t("ISSUE"),
      align: "center",
      valueFormatter: (val, row) => IssueDesc(row),
    },
    {
      field: "user",
      label: t("NOTIFIER"),
      align: "center",
      valueFormatter: (value) => <UserDetail value={value} type="USER" />,
    },
    {
      field: "id",
      label: t("SORUMLU"),
      align: "center",
      valueFormatter: (value, row) => {
        if (!isTaskManagement) {
          return (
            <Tooltip title={"Üyelik paketiniz desteklememektedir."}>
              <span>
                <IconButton
                  onClick={() => {}}
                  size="small"
                  color="primary"
                  disabled={true}
                >
                  <FontAwesomeIcon icon={faUserEdit} />
                </IconButton>
              </span>
            </Tooltip>
          );
        } else {
          if (row.responsibleUser || row.responsibleUserGroup) {
            return (
              <div>
                <div>{smartTimeDiff(row.responsibleChangeDate)}</div>
                <div>
                  {row.responsibleUser ? (
                    <UserDetail value={row.responsibleUser} type="USER" />
                  ) : (
                    <UserDetail
                      value={row.responsibleUserGroup}
                      type="USER_GROUP"
                    />
                  )}
                </div>
              </div>
            );
          } else {
            let isTaskAssignable = checkRoleByIssue(
              [ROLES.THINGMANAGER, ROLES.POWER_USER, ROLES.ACCOUNTOWNER],
              userRole,
              userInfo,
              userGroups,
              userOrganizations,
              row
            );
            return (
              isTaskAssignable && (
                <IconButton
                  onClick={() => {
                    openTaskAssign(row);
                  }}
                  size="small"
                  color="primary"
                  title={t("ATAMA")}
                >
                  <FontAwesomeIcon icon={faUserEdit} />
                </IconButton>
              )
            );
          }
        }
      },
    },
    {
      field: "status",
      label: t("DURUM"),
      align: "center",

      valueFormatter: (value, row) => {
        let isTaskClosable = checkRoleByIssue(
          [
            ROLES.OPERATOR,
            ROLES.THINGMANAGER,
            ROLES.POWER_USER,
            ROLES.ACCOUNTOWNER,
          ],
          userRole,
          userInfo,
          userGroups,
          userOrganizations,
          row
        );
        if (value === "OPEN" || value === "REJECT") {
          return (
            <>
              {isTaskClosable && (
                <FormControlLabel
                  control={
                    <GreenSwitch
                      //checked={false}
                      onChange={() => {
                        startCloseIssue(row);
                      }}
                    />
                  }
                  label={t(value)}
                />
              )}
              {!isTaskClosable && (
                <FormControlLabel
                  disabled
                  control={<GreenSwitch />}
                  label={t(value)}
                />
              )}
            </>
          );
        } else if (value === "CONFIRMATION") {
          return (
            <FormControlLabel
              disabled
              control={<Switch checked />}
              label={t(value)}
            />
          );
        } else {
          return (
            <FormControlLabel
              disabled
              control={<Switch checked />}
              label={t(value)}
            />
          );
        }
      },
    },
    {
      field: "closeDate",
      label: t("CLOSE_DATE"),
      align: "center",
      valueFormatter: (value, row) => {
        let paused = row.paused;
        let pauseDate = getDateTimeWFormat(row.pauseDate);
        return (
          <>
            {paused && (
              <Tooltip title={pauseDate}>
                <PauseIcon fontSize="small" />
              </Tooltip>
            )}

            {value == null ? null : (
              <div>
                <div>
                  <i>{smartTimeDiff(row.closeDate)}</i>
                </div>
                <div>{row.closeNote}</div>
              </div>
            )}
          </>
        );
      },
    },
  ];
  useEffect(() => {
    refresh();
  }, [history.location.search]);

  useEffect(() => {
    if (accountId)
      AccPreferencesService.findByAccount(accountId, (data) => {
        console.log("data", data);
        setAccPreferences(data);
      });
  }, [accountId]);

  const refresh = () => {
    tableRef.current.refreshTable();
  };

  useEffect(() => {
    const refreshTimer = window.setInterval(() => {
      refresh();
    }, refreshPeriod * 1000);

    if (!accountId) {
      setTimeout(() => {
        refresh();
      }, 1000);
    }

    return () => {
      window.clearInterval(refreshTimer);
    };
  }, [refreshPeriod]);

  const openTaskAssign = (data) => {
    setIsNewTaskDialog(true);
    setSelectedIssue(data);
  };

  const handleIssueChange = (prop, val) => {
    setIssueDTO({ ...issueDTO, [prop]: val });
  };

  const saveIssue = () => {
    setLoading(true);
    let req = requests.getSimplePostRequest(ISSUE.save, {
      ...issueDTO,
      status: 0,
      user: userInfo,
      account: { id: accountId },
      notificationDate: new Date(),
    });
    FaAxios(req)
      .then((response) => {
        showAlert(!issueDTO.id ? t("SAVED") : t("EDITED"), "success");
        refresh();
        setFiles([]);
        setIssueDTO({
          thing: null,
          description: "",
          user: {},
          notificationDate: "",
          status: "",
          notifier: userInfo?.fullname,
          hashtag: "",
          issueClass: {},
        });
        setIsNewIssueDialog(false);
        setLoading(false);
      })
      .catch((error) => {
        showAlert(error.message, "error");
        setLoading(false);
      });
  };

  const onDoubleClick = (row) => {
    const tmpTo = {
      pathname: `issues/detail/${row.id}`,
      breadCrumbKey: `/issues/detail/:id`,
    };
    history.push(tmpTo);
  };

  const startCloseIssue = (row) => {
    setSelectedIssue(row);
    setIsSorunKapatDialog(true);
  };

  const noThingOnCheck = (checked) => {
    if (checked) {
      ThingService.getSystemThing(
        accountId,
        (data) => {
          setIssueDTO((prev) => {
            return { ...prev, thing: data };
          });
        },
        (error) => {}
      );
    } else {
      setIssueDTO((prev) => {
        return { ...prev, thing: null };
      });
    }
  };

  const onCreate = (data) => {
    refresh();
  };

  const addNewIssueRender = () => {
    return <IssueCreate ref={dialogRef} onCreate={onCreate}></IssueCreate>;
  };

  const issueClosed = () => {
    setIsSorunKapatDialog(false);
    refresh();
  };

  const closeIssueRender = () => {
    return (
      <IssueClose
        onChange={issueClosed}
        open={isSorunKapatDialog}
        onClose={() => {
          setIsSorunKapatDialog(false);
        }}
        accPreferences={accPreferences}
        issue={selectedIssue}
      />
    );
  };

  const handleChangeGeneric = (method, dto, prop, val, prop2, val2) => {
    if (prop2) method({ ...dto, [prop]: val, [prop2]: val2 });
    else method({ ...dto, [prop]: val });
  };

  const addNewTaskRender = () => {
    return (
      <TaskEdit
        onChange={taskOnChange}
        open={isNewTaskDialog}
        onClose={() => {
          setIsNewTaskDialog(false);
        }}
        accPreferences={accPreferences}
        issue={selectedIssue}
      />
    );
  };

  const taskOnChange = () => {
    refresh();
    setIsNewTaskDialog(false);
  };

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        {addNewIssueRender()}
        {addNewTaskRender()}
        {closeIssueRender()}
        {!homepage && (
          <Grid
            item
            container
            spacing={1}
            style={{ marginBottom: 5, overflowY: "auto" }}
          >
            <Adsense value="RklmAltMin" />

            <Grid item lg={3} xs={9} id="statusSpeedFilter">
              <Grid item xs={12} className={labelClasses.LabelLastRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("DURUM")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <StatusButtonGroup
                  data-fa-section="STATUS_BUTTON_GROUP"
                  value={searchForm.status}
                  onChange={(data) => {
                    const temp = { ...searchForm, status: data };
                    setSearchForm(temp);
                    tableRef.current.search(temp);
                  }}
                />
              </Grid>
            </Grid>

            <Grid item lg={4} xs={9} id="dateSpeedFilter">
              <Grid item xs={12} className={labelClasses.LabelLastRow}>
                <Typography className={labelClasses.LabelHeader}>
                  {t("TARIH")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <DateButtonGroup
                    data-fa-section="DATE_BUTTON_GROUP"
                    value={searchForm.notificationDateValue}
                    onChange={(data) => {
                      const temp = {
                        ...searchForm,
                        notificationDateValue: data,
                        notificationDateStart: calculateDate(data),
                      };

                      setSearchForm(temp);
                      tableRef.current.search(temp);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={5} xs={3} style={{ marginTop: 20 }}>
              <Grid container justifyContent="flex-end">
                <Grid item lg={2} xs={12}>
                  <Tooltip title={t("Yenile")}>
                    <IconButton onClick={refresh}>
                      <Refresh />
                    </IconButton>
                  </Tooltip>
                  <RefreshPeriodButton
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    refreshPeriod={refreshPeriod}
                    setRefreshPeriod={setRefreshPeriod}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <ThingsDataTable
          searchUrl={ISSUE.search + "?accountId=" + localStorage.accountId}
          exportUrl={ISSUE.export + "?accountId=" + localStorage.accountId}
          searchForm={searchForm}
          queryParams={{
            page: 0,
            size: 10,
            isAsc: false,
            sort: "notificationDate",
            accountId: localStorage.accountId,
          }}
          columns={columns}
          ref={tableRef}
          showHeaderText={false}
          faOnDoubleClick={onDoubleClick}

          // showEditButton
          // onEditAction={onRowEdit}
          // showDeleteButton
          // onDeleteAction={onRowDelete}
        />
        {!homepage && (
          <Grid
            item
            container
            alignItems="center"
            justifyContent="center"
            spacing={1}
          >
            <Grid item xs={1} className={"thingsDataTableButtons"}>
              <FaButton
                variant="contained"
                disabled={loading}
                color="primary"
                size="medium"
                faClick={() => {
                  dialogRef.current.openDialog();
                }}
                startIcon={<AddIcon />}
                data-fa-button="REPORT_ISSUE"
                id="addIssueButton"
              >
                {t("REPORT_ISSUE")}
              </FaButton>
            </Grid>
            <Grid item xs={4}>
              <div>{searchInfo}</div>
            </Grid>
            <Grid item xs={6}>
              <FaSearch
                onSearch={searchSubmit}
                onClear={searchClear}
                onSummarySearch={searchSummary}
                setSearchInfo={setSearchInfo}
                faSummary={searchForm?.summary}
                faClassName="appSearchBar"
              >
                <FaInput
                  label={t("ID")}
                  value={searchForm.id}
                  onChange={(event) => {
                    handleChangeGeneric(
                      setSearchForm,
                      searchForm,
                      "id",
                      event.target.value
                    );
                  }}
                />
                <FaInput
                  label={t("NOTIFIER")}
                  value={searchForm.notifier}
                  onChange={(event) => {
                    handleChangeGeneric(
                      setSearchForm,
                      searchForm,
                      "notifier",
                      event.target.value
                    );
                  }}
                />
                <UserAndGroupAutoComplete
                  label={t("SORUMLU")}
                  value={{
                    user: searchForm?.taskUser,
                    userGroup: searchForm?.taskUserGroup,
                  }}
                  accountId={accountId}
                  onChange={(data) => {
                    setSearchForm({
                      ...searchForm,
                      taskUser: data && data.user ? data.user : null,
                      taskUserId: data && data.user ? data.user?.id : null,
                      taskUserGroup:
                        data && data.userGroup ? data.userGroup : null,
                      taskUserGroupId:
                        data && data.userGroup ? data.userGroup?.id : null,
                    });
                  }}
                  data-fa-search="USER"
                />
                <ThingAutoComplete
                  accountId={accountId}
                  value={searchForm.thing}
                  onChange={(data) => {
                    handleChangeGeneric(
                      setSearchForm,
                      searchForm,
                      "thing",
                      data,
                      "thingId",
                      data?.id
                    );
                  }}
                  id="thingAutoComplete"
                />
                <IssueClassAutoComplete
                  accountId={accountId}
                  value={searchForm.issueClass}
                  onChange={(data) => {
                    handleChangeGeneric(
                      setSearchForm,
                      searchForm,
                      "issueClass",
                      data,
                      "issueClassId",
                      data?.id
                    );
                  }}
                />
                <DateRangeSelector
                  onChange={(data) => {
                    setSearchForm({
                      ...searchForm,
                      notificationDateStart: data.startDate,
                      notificationDateEnd: data.endDate,
                    });
                  }}
                />
                <FaInput
                  label={t("DESCRIPTION")}
                  value={searchForm.description}
                  onChange={(event) => {
                    handleChangeGeneric(
                      setSearchForm,
                      searchForm,
                      "description",
                      event.target.value
                    );
                  }}
                />
              </FaSearch>
            </Grid>
          </Grid>
        )}
      </Suspense>
    </>
  );
}
