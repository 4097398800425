import { Card, CardHeader, Grid } from "@material-ui/core";
import { ResponsiveHeatMap } from "@nivo/heatmap";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomerInteractionService } from "services/CustomerInteractionService";
import { labelStyles } from "styles/ThingsLabelStyle";
import i18n from "../../i18n";

const CustomerInteractionDayTimeHeatmap = forwardRef((props, ref) => {
  const { t } = useTranslation(["customer", "schedulePlan"], { i18n });
  const labelClasses = labelStyles();

  const [dayTimeHeatmapList, setDayTimeHeatmapList] = useState(null);

  const [masterSearchForm, setMasterSearchForm] = useState(null);

  useImperativeHandle(ref, () => ({
    refreshTable(searchForm) {
      if (searchForm) {
        setMasterSearchForm(searchForm);
        CustomerInteractionService.getDayTimeHeatmap(
          searchForm,
          getDayTimeHeatmapOnSuccess,
          (error) => {}
        );
      }
    },
  }));

  const getDayTimeHeatmapOnSuccess = (result) => {
    let list = [];
    for (let hour = 0; hour < 24; hour++) {
      let saat = {
        id: "" + hour,
        data: result
          .filter((m) => m.hour === hour)
          .map((item) => {
            return {
              x: t("schedulePlan:" + item.day),
              y: "" + item.value,
            };
          }),
      };

      list.push(saat);
    }

    setDayTimeHeatmapList(list);
  };

  return (
    <>
      {dayTimeHeatmapList && (
        <Grid container spacing={2} style={{ overflowY: "auto" }}>
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <Card data-fa-section="CUSTOMER_INTERACTION_DAY_TIME_HEATMAP">
                  <CardHeader
                    title={t("interaction_by_time_of_day")}
                  ></CardHeader>
                  <div style={{ width: "100%", height: "21rem" }}>
                    <ResponsiveHeatMap
                      data={dayTimeHeatmapList}
                      margin={{ top: 40, right: 90, bottom: 80, left: 90 }}
                      //valueFormat=">-.2s"
                      enableLabels={false}
                      xInnerPadding={0.05}
                      yInnerPadding={0.05}
                      axisRight={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: t("time_zone"),
                        legendPosition: "middle",
                        legendOffset: 70,
                      }}
                      axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: t("time_zone"),
                        legendPosition: "middle",
                        legendOffset: -72,
                      }}
                      colors={{
                        type: "diverging",
                        scheme: "green_blue",
                        divergeAt: 0.5,
                        minValue: 0,
                      }}
                      emptyColor="#000000"
                      legends={[
                        {
                          anchor: "bottom",
                          translateX: 0,
                          translateY: 30,
                          length: 400,
                          thickness: 8,
                          direction: "row",
                          tickPosition: "after",
                          tickSize: 3,
                          tickSpacing: 4,
                          tickOverlap: false,
                          tickFormat: ">-.2s",
                          title: t("chart_interaction_count"),
                          titleAlign: "start",
                          titleOffset: 4,
                        },
                      ]}
                    />
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
});
export default CustomerInteractionDayTimeHeatmap;
