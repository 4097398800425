import {
  faAtom,
  faBinoculars,
  faBoxOpen,
  faBuilding,
  faBullhorn,
  faCalendar,
  faCalendarWeek,
  faChartLine,
  faCloud,
  faCreditCard,
  faEdit,
  faEnvelope,
  faEnvelopeOpenText,
  faExclamationCircle,
  faGlobe,
  faHandPointUp,
  faHandshake,
  faHistory,
  faLightbulb,
  faLink,
  faMap,
  faParagraph,
  faQrcode,
  faRecycle,
  faRobot,
  faRoute,
  faShoppingBasket,
  faSitemap,
  faStickyNote,
  faTags,
  faTasks,
  faThumbsUp,
  faTicketAlt,
  faTrain,
  faTv,
  faUser,
  faUserAlt,
  faUserLock,
  faUsers,
  faWallet,
  faWarehouse,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AccountBox,
  Archive,
  Assignment,
  Ballot,
  Dashboard,
  Help,
  List,
  MenuBook,
  Repeat,
  SelectAll,
  Web,
} from "@material-ui/icons";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import ContactMailOutlinedIcon from "@material-ui/icons/ContactMailOutlined";
import DonutLargeIcon from "@material-ui/icons/DonutLarge";
import EventIcon from "@material-ui/icons/Event";
import EventNoteIcon from "@material-ui/icons/EventNote";
import ForumIcon from "@material-ui/icons/Forum";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import TodayIcon from "@material-ui/icons/Today";
import ThingsCampaignIcon from "components/icons/ThingsCampaignIcon";
import ThingsSurveyIcon from "components/icons/ThingsSurveyIcon";
import { THINGS_ENVIRONMENT } from "environments/Environments";
import AccessLogDetail from "features/accessLog/AccessLogDetail";
import AccountExportData from "features/accountDetails/AccountExportData";
import AccountInteractionPage from "features/accountDetails/AccountInteractionPage";
import AccountNotifications from "features/accountDetails/AccountNotifications";
import AccountSLALevels from "features/accountDetails/AccountSLALevels";
import AccountIncentiveScreen from "features/accountIncentiveScreen";
import AccountUserInvitation from "features/accountUserInvitation";
import AdditionalFieldsEdit from "features/additionalFields/additionalFieldsEdit";
import Announcement from "features/announcement";
import BeneficiaryAccountSelection from "features/beneficiaryPortal/BeneficiaryAccountSelection";
import BeneficiaryLogin from "features/beneficiaryPortal/BeneficiaryLogin";
import BeneficiaryLoginLdap from "features/beneficiaryPortal/BeneficiaryLoginLdap";
import BeneficiaryPortalCampaignDetail from "features/beneficiaryPortal/campaign/BeneficiaryPortalCampaignDetail";
import CampaignStatus from "features/beneficiaryPortal/emailLink/CampaignStatus";
import BeneficiaryHomepage from "features/beneficiaryPortal/homepage";
import MyCampaigns from "features/beneficiaryPortal/homepage/MyCampaigns";
import CustomerInfo from "features/beneficiaryPortal/info";
import MyInteractions from "features/beneficiaryPortal/interactions/MyInteractions";
import OpenIssues from "features/beneficiaryPortal/issues";
import BeneficiaryMessages from "features/beneficiaryPortal/messages/BeneficiaryMessages";
import MyPermissions from "features/beneficiaryPortal/permissions";
import CustomerThings from "features/beneficiaryPortal/things";
import Calendar from "features/calendar/index";
import Campaign from "features/campaign";
import ChangePassword from "features/changePassword";
import Coupon from "features/coupon";
import CouponDetail from "features/coupon/CouponDetail";
import Customers from "features/customer";
import CustomerDetail from "features/customer/CustomerDetail";
import CustomerInteractions from "features/customerInteraction";
import CustomerInteractionIndoorMap from "features/customerInteraction/CustomerInteractionIndoorMap";
import CustomerInteractionMap from "features/customerInteraction/CustomerInteractionMap";
import CustomerList from "features/customerList";
import CustomerListDetail from "features/customerList/CustomerListDetail";
import CustomerMessage from "features/customerMessage";
import CustomerInteractionDashboard from "features/dashboard/CustomerInteractionDashboard";
import ProductDashboard from "features/dashboard/ProductDashboard";
import Equipments from "features/equipment";
import Event from "features/event";
import ForbiddenPage from "features/forbiddenPage";
import ForgotPassword from "features/forgotPassword";
import Homepage from "features/homepage";
import Inbox from "features/inbox";
import InboxDetail from "features/inbox/InboxDetail";
import InvitationAcceptionScreen from "features/invitationAcceptionScreen";
import IssueClass from "features/issueClass";
import IssueClassDetail from "features/issueClassDetail";
import IssueSla from "features/issues/IssueSla";
import Journal from "features/journal";
import JournalType from "features/journalType";
import LegalText from "features/legalText";
import LegalTextDetail from "features/legalText/LegalTextDetail";
import Like from "features/like";
import LikeDetail from "features/like/LikeDetail";
import LocationDetail from "features/location/LocationDetail";
import Location from "features/location/index";
import Login from "features/login";
import LoginT2C from "features/login/LoginT2C";
import LoginTF from "features/login/LoginTF";
import MessageTemplate from "features/messageTemplate";
import EmailDesigner from "features/messageTemplate/EmailDesigner";
import MessageTemplateDetail from "features/messageTemplate/MessageTemplateDetail";
import MessageTemplateEmailEditor from "features/messageTemplate/MessageTemplateEmailEditor";
import MessageTemplateTargets from "features/messageTemplateTargets";
import Messages from "features/messages";
import Organization from "features/organization";
import Patrol from "features/patrol";
import PatrolDetail from "features/patrol/PatrolDetail";
import PatrolDef from "features/patrolDef";
import PatrolDefDetail from "features/patrolDef/PatrolDefDetail";
import PeriodicReport from "features/periodicReport";
import PeriodicReportDetail from "features/periodicReport/PeriodicReportDetail";
import Policy from "features/policy";
import PolicyDetail from "features/policy/PolicyDetail";
import Project from "features/project";
import ProjectDetail from "features/project/ProjectDetail";
import Proxy from "features/proxy";
import Queue from "features/queue";
import QueueDetail from "features/queue/QueueDetail";
import RecurrenceDetail from "features/recurrence/RecurrenceDetail";
import Recurrence from "features/recurrence/index";
import RelationType from "features/relationType";
import BeneficiaryNoteReply from "features/report/BeneficiaryNoteReply";
import Customer from "features/report/Customer";
import PublicInfo from "features/report/PublicInfo";
import ReportIssue from "features/report/ReportIssue";
import ReportIssueFood from "features/report/ReportIssueFood";
import ReportLike from "features/report/ReportLike";
import ReportSuggestion from "features/report/ReportSuggestion";
import ReportUserContent from "features/report/ReportUserContent";
import RegisterCampaign from "features/report/campaign/RegisterCampaign";
import RegisterCampaignResult from "features/report/campaign/RegisterCampaignResult";
import Report from "features/report/index";
import PublicIssueStatus from "features/report/issue/PublicIssueStatus";
import Survey from "features/report/survey/Survey";
import SurveyResult from "features/report/survey/SurveyResult";
import Reservation from "features/reservation";
import SchedulePlan from "features/schedulePlan";
import ServiceOperationClass from "features/serviceOperationClass";
import ServiceOperationClassDetail from "features/serviceOperationClass/ServiceOperationClassDetail";
import SignUp from "features/signUp";
import SignUpThings2Care from "features/signUp/SignUpThings2Care";
import SlaCalendarEdit from "features/slaCalendar/edit";
import SlaCalendar from "features/slaCalendar/index";
import SlaContractDetail from "features/slaContract/SlaContractDetail";
import SlaContractMonitor from "features/slaContract/SlaContractMonitor";
import SlaIssuesList from "features/slaContract/SlaIssuesList";
import SlaContract from "features/slaContract/index";
import SmsAndEmailConfirmation from "features/smsAndEmailConfirmation";
import SolutionDetail from "features/solutionDetail";
import Solutions from "features/solutions";
import ClosedIssueOpenTimeByThingClass from "features/statistic/issue/ClosedIssueOpenTimeByThingClass";
import IssueClassCountByDate from "features/statistic/issue/IssueClassCountByDate";
import IssueCountByLocation from "features/statistic/issue/IssueCountByLocation";
import IssueCountByOrganization from "features/statistic/issue/IssueCountByOrganization";
import IssueCountByThingClass from "features/statistic/issue/IssueCountByThingClass";
import IssueCountByUser from "features/statistic/issue/IssueCountByUser";
import IssueCountByVendor from "features/statistic/issue/IssueCountByVendor";
import IssueCountStat from "features/statistic/issue/IssueCountStat";
import OpenIssueOpenTimeByThingClass from "features/statistic/issue/OpenIssueOpenTimeByThingClass";
import ThingCountByLocation from "features/statistic/thing/ThingCountByLocation";
import ThingCountByOrganization from "features/statistic/thing/ThingCountByOrganization";
import ThingCountByVendor from "features/statistic/thing/ThingCountByVendor";
import ThingCountStat from "features/statistic/thing/ThingCountStat";
import IyzicoCallback from "features/subscriptions/IyzicoCallback";
import Trial from "features/subscriptions/Trial";
import Suggestions from "features/suggestions";
import SuggestionDetail from "features/suggestions/SuggestionDetail";
import SurveyAnswerList from "features/survey/SurveyAnswerList";
import SurveyAllAnswers from "features/survey/analysis/SurveyAllAnswers";
import SurveyAnalysis from "features/survey/analysis/SurveyAnalysis";
import LdapSync from "features/sync/LdapSync";
import Tasks from "features/task";
import TeamRoles from "features/teamRoles";
import TeamRolesDetail from "features/teamRoles/TeamRolesDetail";
import ThingArchives from "features/thingArchives";
import ThingChanges from "features/thingChange";
import ThingChangeDetail from "features/thingChange/ThingChangeDetail";
import ThingClass from "features/thingClass";
import ThingClassDetail from "features/thingClassDetail";
import ThingRelationExplorer from "features/thingRelationExplorer";
import MyThing from "features/things/MyThing";
import MyThings from "features/things/MyThings";
import ThingsByClass from "features/things/ThingsByClass";
import ThingsIndoorMap from "features/thingsMap/ThingsIndoorMap";
import Todo from "features/todo/index";
import TodoDetail from "features/todo/todoDetail";
import Tutorials from "features/tutorial";
import TutorialViewer from "features/tutorial/TutorialViewer";
import Unsubscribe from "features/unsubscribe";
import UserContent from "features/userContent";
import UserContentDetail from "features/userContent/UserContentDetail";
import UserGroup from "features/userGroup";
import Vendor from "features/vendor";
import React from "react";
import FaProfileUpdate from "react-base-fa/dist/fa/faProfileUpdate";
import VerificationPage from "react-base-fa/dist/features/verificationPage";
import { AUT } from "services/faApi";
import { getThingsPackage, isTNF } from "services/utils";
import AccountDetails from "./features/accountDetails";
import AccountUsers from "./features/accountUsers";
import Campaigns from "./features/campagins";
import CampaignCustomers from "./features/campaign/CampaignCustomers";
import CampaignDetail from "./features/campaign/CampaignDetail";
import Checkout from "./features/checkout";
import UseCoupon from "./features/coupon/UseCoupon";
import CustomerMessageDetail from "./features/customerMessage/CustomerMessageDetail";
import IssueDetail from "./features/issueDetail";
import IssueDetailFood from "./features/issueDetailFood";
import Issues from "./features/issues";
import IssuesFood from "./features/issuesFood";
import MissingData from "./features/missingData";
import PrintQR from "./features/printQr";
import Profile from "./features/profile";
import QRTemplate from "./features/qrTemplate";
import LabelDesigner from "./features/qrTemplate/LabelDesigner";
import LabelDesignerSample from "./features/qrTemplate/LabelDesignerSample";
import SolutionStats from "./features/statistic/SolutionStats";
import StorageStat from "./features/statistic/StorageStat";
import Stock from "./features/stock";
import StockTaking from "./features/stockTaking";
import StockTakingDetail from "./features/stockTaking/StockTakingDetail";
import Subscriptions from "./features/subscriptions";
import Surveys from "./features/survey";
import SurveyDetail from "./features/survey/SurveyDetail";
import SurveyQuestionLibrary from "./features/surveyQuestionLibrary";
import ThingDetail from "./features/thingDetail";
import ThingDetailFood from "./features/thingDetailFood";
import Things from "./features/things";
import ThingsDetail from "./features/things/detay";
import ThingsFood from "./features/thingsFood";
import ThingsMap from "./features/thingsMap";
export const permittedRoles = [
  "BENEFICIARY",
  "OPERATOR",
  "THINGMANAGER",
  "POWER_USER",
  "ACCOUNTOWNER",
  "FOOD",
  "SLA",
  "PATROL",
  "QUEUE",
  "POLICY",
  "CUSTOMER",
  "CAMPAIGN",
  "SURVEY",
  "PUBLIC_BENEFICIARY",
  "NO_ACCOUNT",
  "JOURNAL",
];

const THINGS_PACKAGE = getThingsPackage();

// childRoute'ları componenti boş olacak şekilde routelar içerisine ekler. Eklenmezse alt menülere erişim sağlanamaz
const handleContextRoutes = (routes) => {
  let list = [...routes];

  routes.forEach((element) => {
    //if (!!!element.component) console.log("Null component:", element.path);

    if (element.childRoutes && element.childRoutes.length > 0) {
      const cList = handleChildRoutes(element.childRoutes, list);
      cList.forEach((ce) => {
        list.push(ce);
      });
    }
  });

  return list;
};

const handleChildRoutes = (routes, mainList) => {
  let list = [];

  routes.forEach((e) => {
    if (e.path) {
      let exists = mainList.find((x) => x.path === e.path);
      if (!exists) list.push({ ...e, component: null, menu: false });
    } else list.push({ ...e, component: null, menu: false });
  });

  return list;
};

const thingContextRoutes = [
  {
    path: "/print-qr",
    component: PrintQR,
    label: "CREATE_QR",
    exact: true,
    menu: true,
    permittedRoles: ["ACCOUNTOWNER", "FOOD", "THINGMANAGER", "POWER_USER"],
    permittedModules: ["PRINT_QR"],
    icon: <FontAwesomeIcon icon={faTicketAlt} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/print-qr",
    component: PrintQR,
    label: "CREATE_QR",
    exact: true,
    menu: false,
    permittedRoles: ["ACCOUNTOWNER", "FOOD", "THINGMANAGER", "POWER_USER"],
    forbiddenRoles: ["PRINT_QR"],
    icon: <FontAwesomeIcon icon={faTicketAlt} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },

  {
    path: "/myThing",
    component: MyThing,
    label: "MY_THING",
    exact: true,
    menu: true,
    permittedRoles: ["ACCOUNTOWNER", "FOOD", "THINGMANAGER", "POWER_USER"],
    permittedModules: ["MY_THING1"],
    icon: <FontAwesomeIcon icon={faQrcode} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    id: "menuMyThing",
  },

  {
    path: "/myThings",
    component: MyThings,
    label: "MY_THINGS",
    exact: true,
    menu: true,
    permittedRoles: ["ACCOUNTOWNER", "FOOD", "THINGMANAGER", "POWER_USER"],
    permittedModules: ["MY_THINGS"],
    icon: <FontAwesomeIcon icon={faQrcode} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    id: "menuMyThings",
  },

  {
    path: "/t00",
    label: "THINGS",
    exact: true,
    menu: true,
    permittedRoles: [
      "BENEFICIARY",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
      "ACCOUNTOWNER",
      "FOOD",
    ],
    permittedModules: ["THINGS_2_CARE"],
    forbiddenRoles: ["MY_THING1", "MY_THINGS"],
    icon: <FontAwesomeIcon icon={faQrcode} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    id: "menuThings",
    childRoutes: [
      // {
      //   path: "/products",
      //   component: Products,
      //   label: "PRODUCTS",
      //   queryParams: { page: 0, size: 10, isAsc: false, sort: "createDate" },
      //   exact: true,
      //   menu: true,
      //   permittedRoles: [
      //     "BENEFICIARY",
      //     "OPERATOR",
      //     "THINGMANAGER",
      //     "POWER_USER",
      //     "ACCOUNTOWNER",
      //     "FOOD",
      //   ],
      //   icon: <FontAwesomeIcon icon={faBoxOpen} />,
      //   breadCrumb: true,
      //   level: 1,
      //   extraProps: {},
      // },
      {
        path: "/things",
        component: Things,
        label: "THINGS",
        queryParams: { page: 0, size: 10, isAsc: false, sort: "createDate" },
        exact: true,
        menu: true,
        permittedRoles: [
          "BENEFICIARY",
          "OPERATOR",
          "THINGMANAGER",
          "POWER_USER",
          "ACCOUNTOWNER",
          "FOOD",
        ],
        icon: <FontAwesomeIcon icon={faQrcode} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      // {
      //   path: "/equipments",
      //   component: Equipments,
      //   label: "EQUIPMENTS",
      //   queryParams: { page: 0, size: 10, isAsc: false, sort: "createDate" },
      //   exact: true,
      //   menu: true,
      //   permittedRoles: ["THINGS_2_CARE"],
      //   icon: <FontAwesomeIcon icon={faTv} />,
      //   breadCrumb: true,
      //   level: 1,
      //   extraProps: {},
      // },
      {
        path: "/mapThings",
        component: ThingsMap,
        label: "MAP",
        exact: true,
        menu: true,
        permittedRoles: [
          "OPERATOR",
          "THINGMANAGER",
          "POWER_USER",
          "ACCOUNTOWNER",
          "FOOD",
        ],
        icon: <FontAwesomeIcon icon={faGlobe} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/thingChanges",
        component: ThingChanges,
        label: "RESPONSIBILITIES",
        queryParams: { page: 0, size: 10, isAsc: false, sort: "changeDate" },
        exact: true,
        menu: true,
        permittedRoles: ["RESPONSIBILITY"],
        icon: <FontAwesomeIcon icon={faUsers} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/thingArchives",
        component: ThingArchives,
        label: "ARCHIVE",
        queryParams: { page: 0, size: 10, isAsc: false, sort: "createDate" },
        exact: true,
        menu: true,
        permittedRoles: [
          //"BENEFICIARY",
          "OPERATOR",
          "THINGMANAGER",
          "POWER_USER",
          "ACCOUNTOWNER",
          "FOOD",
        ],
        icon: <Archive />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/announcement",
        component: Announcement,
        label: "ANNOUNCEMENT",
        queryParams: { page: 0, size: 10, isAsc: true, sort: "startDate" },
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        icon: <FontAwesomeIcon icon={faBullhorn} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/journal",
        component: Journal,
        label: "JOURNAL",
        queryParams: { page: 0, size: 10, isAsc: true, sort: "id" },
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["JOURNAL"],
        icon: <EventNoteIcon />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/reservation",
        component: Reservation,
        label: "RESERVATION",
        queryParams: {
          page: 0,
          size: 10,
          isAsc: false,
          sort: "reservationDateStart",
        },
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["RESERVATION"],
        icon: <TodayIcon />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
    ],
  },
  {
    path: "/t001",
    label: "INTERACTION_THINGS",
    exact: true,
    menu: true,
    permittedRoles: [
      "BENEFICIARY",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
      "ACCOUNTOWNER",
      "FOOD",
    ],
    forbiddenRoles: ["THINGS_2_CARE", "MY_THING1", "MY_THINGS"],
    icon: <FontAwesomeIcon icon={faQrcode} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    id: "menuThings",
    childRoutes: [
      {
        path: "/things",
        component: Things,
        label: "THINGS_LIST",
        queryParams: { page: 0, size: 10, isAsc: false, sort: "createDate" },
        exact: true,
        menu: true,
        permittedRoles: [
          "BENEFICIARY",
          "OPERATOR",
          "THINGMANAGER",
          "POWER_USER",
          "ACCOUNTOWNER",
          "FOOD",
        ],
        icon: <FontAwesomeIcon icon={faQrcode} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuThingList",
      },
      {
        path: "/equipments",
        component: Equipments,
        label: "EQUIPMENTS",
        queryParams: { page: 0, size: 10, isAsc: false, sort: "createDate" },
        exact: true,
        menu: true,
        permittedRoles: ["THINGS_2_CARE"],
        icon: <FontAwesomeIcon icon={faTv} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/mapThings",
        component: ThingsMap,
        label: "MAP",
        exact: true,
        menu: true,
        permittedRoles: [
          "OPERATOR",
          "THINGMANAGER",
          "POWER_USER",
          "ACCOUNTOWNER",
          "FOOD",
        ],
        icon: <FontAwesomeIcon icon={faGlobe} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/thingChanges",
        component: ThingChanges,
        label: "Sorumluluklar",
        queryParams: { page: 0, size: 10, isAsc: false, sort: "changeDate" },
        exact: true,
        menu: true,
        permittedRoles: ["RESPONSIBILITY"],
        icon: <FontAwesomeIcon icon={faUsers} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/thingArchives",
        component: ThingArchives,
        label: "ARCHIVE",
        queryParams: { page: 0, size: 10, isAsc: false, sort: "createDate" },
        exact: true,
        menu: true,
        permittedRoles: [
          //"BENEFICIARY",
          "OPERATOR",
          "THINGMANAGER",
          "POWER_USER",
          "ACCOUNTOWNER",
          "FOOD",
        ],
        icon: <Archive />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/announcement",
        component: Announcement,
        label: "ANNOUNCEMENT",
        queryParams: { page: 0, size: 10, isAsc: true, sort: "startDate" },
        exact: true,
        menu: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        icon: <FontAwesomeIcon icon={faBullhorn} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/journal",
        component: Journal,
        label: "JOURNAL",
        queryParams: { page: 0, size: 10, isAsc: true, sort: "id" },
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["JOURNAL"],
        icon: <EventNoteIcon />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/reservation",
        component: Reservation,
        label: "RESERVATION",
        queryParams: { page: 0, size: 10, isAsc: true, sort: "id" },
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["RESERVATION"],
        icon: <TodayIcon />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
    ],
  },
  {
    path: "/emailDesigner",
    component: EmailDesigner,
    label: "E-posta Tasarımcısı",
    exact: true,
    menu: false,
    permittedRoles: [
      "BENEFICIARY",
      "THINGMANAGER",
      "POWER_USER",
      "ACCOUNTOWNER",
      "FOOD",
    ],
    icon: <FontAwesomeIcon icon={faGlobe} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/thingsByClass",
    component: ThingsByClass,
    label: "THINGS_BY_CLASS",
    queryParams: { page: 0, size: 10, isAsc: false, sort: "createDate" },
    exact: true,
    menu: false,
    permittedRoles: [
      "BENEFICIARY",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
      "ACCOUNTOWNER",
      "FOOD",
    ],
    icon: <FontAwesomeIcon icon={faQrcode} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/t02",
    //component: EmptyPage,
    label: "USER_INTERACTION",
    exact: true,
    menu: true,
    permittedRoles: [
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
      "ACCOUNTOWNER",
      "BENEFICIARY",
    ],
    permittedModules: ["THINGS_2_CARE"],
    icon: <Repeat />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    id: "menuCustomerInteraction",
    childRoutes: [
      {
        path: "/customerInteractions",
        component: CustomerInteractions,
        label: "ALL_CUSTOMER_INTERACTIONS",
        menu: true,
        queryParams: {
          page: 0,
          size: 10,
          isAsc: false,
          sort: "date",
          accountId: localStorage.accountId,
        },
        exact: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        icon: <SelectAll />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuCustomerInteractions",
      },
      {
        path: "/issues",
        component: Issues,
        label: "SORUNLAR",
        menu: true,
        queryParams: {
          page: 0,
          size: 10,
          isAsc: false,
          sort: "notificationDate",
          accountId: localStorage.accountId,
        },
        exact: true,
        permittedRoles: [
          "OPERATOR",
          "THINGMANAGER",
          "POWER_USER",
          "ACCOUNTOWNER",
          "BENEFICIARY",
        ],
        icon: <FontAwesomeIcon icon={faExclamationCircle} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuIssues",
      },
      {
        path: "/likes",
        component: Like,
        label: "LIKES",
        menu: true,
        queryParams: {
          page: 0,
          size: 10,
          isAsc: false,
          sort: "likeDate",
          accountId: localStorage.accountId,
        },
        exact: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuLikes",
      },
      {
        path: "/suggestions",
        component: Suggestions,
        label: "SUGGESTIONS",
        menu: true,
        queryParams: {
          page: 0,
          size: 10,
          isAsc: false,
          sort: "suggestionDate",
          accountId: localStorage.accountId,
        },
        exact: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        icon: <FontAwesomeIcon icon={faLightbulb} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuSuggestions",
      },
      {
        path: "/surveyInteractions",
        component: SurveyAnswerList,
        label: "SURVEY_ANSWERS",
        exact: true,
        menu: true,
        permittedRoles: ["SURVEY", "SRVY_SIMPLE"],
        icon: <ThingsSurveyIcon />,
        breadCrumb: true,
        level: 2,
        extraProps: {},
      },
      {
        path: "/eventInteractions",
        component: CampaignCustomers,
        queryParams: {
          page: 0,
          size: 10,
          sort: "registerDate",
          isAsc: false,
          type: "EVENT",
        },
        label: "EVENTS",
        exact: true,
        menu: true,
        permittedRoles: ["CAMPAIGN"],
        icon: <EventIcon />,
        breadCrumb: true,
        level: 2,
        extraProps: {},
      },
      {
        path: "/userContent",
        component: UserContent,
        label: "USER_CONTENT",
        queryParams: {
          page: 0,
          size: 10,
          isAsc: true,
          sort: "createDate",
        },
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["USER_CONTENT"],
        icon: <ForumIcon />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/customerInteractionDashboard",
        component: CustomerInteractionDashboard,
        label: "CUSTOMER_INTERACTION_DASHBOARD",
        exact: true,
        menu: true,
        permittedRoles: [
          "CUSTOMER",
          "THINGMANAGER",
          "POWER_USER",
          "ACCOUNTOWNER",
        ],
        icon: <Dashboard />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuCustomerInteractionDashboard",
      },
    ],
  },
  {
    path: "/t021",
    //component: EmptyPage,
    label: "CUSTOMER_INTERACTION",
    exact: true,
    menu: true,
    permittedRoles: [
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
      "ACCOUNTOWNER",
      "BENEFICIARY",
    ],
    forbiddenRoles: ["THINGS_2_CARE"],
    icon: <Repeat />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    id: "menuCustomerInteraction",
    childRoutes: [
      {
        path: "/customerInteractions",
        component: CustomerInteractions,
        label: "Tümü",
        menu: true,
        queryParams: {
          page: 0,
          size: 10,
          isAsc: false,
          sort: "date",
          accountId: localStorage.accountId,
        },
        exact: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        icon: <SelectAll />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuCustomerInteractions",
      },
      {
        path: "/issues",
        component: Issues,
        label: "SORUNLAR",
        menu: true,
        queryParams: {
          page: 0,
          size: 10,
          isAsc: false,
          sort: "notificationDate",
          accountId: localStorage.accountId,
        },
        exact: true,
        permittedRoles: [
          "OPERATOR",
          "THINGMANAGER",
          "POWER_USER",
          "ACCOUNTOWNER",
          "BENEFICIARY",
        ],
        icon: <FontAwesomeIcon icon={faExclamationCircle} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuIssues",
      },
      {
        path: "/likes",
        component: Like,
        label: "LIKES",
        menu: true,
        queryParams: {
          page: 0,
          size: 10,
          isAsc: false,
          sort: "likeDate",
          accountId: localStorage.accountId,
        },
        exact: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        icon: <FontAwesomeIcon icon={faThumbsUp} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuLikes",
      },
      {
        path: "/suggestions",
        component: Suggestions,
        label: "SUGGESTIONS",
        menu: true,
        queryParams: {
          page: 0,
          size: 10,
          isAsc: false,
          sort: "suggestionDate",
          accountId: localStorage.accountId,
        },
        exact: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        icon: <FontAwesomeIcon icon={faLightbulb} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuSuggestions",
      },
      {
        path: "/surveyInteractions",
        component: SurveyAnswerList,
        label: "SURVEY_ANSWERS",
        exact: true,
        menu: true,
        permittedRoles: ["SURVEY", "SRVY_SIMPLE"],
        icon: <ThingsSurveyIcon />,
        breadCrumb: true,
        level: 2,
        extraProps: {},
      },
      {
        path: "/eventInteractions",
        component: CampaignCustomers,
        queryParams: {
          page: 0,
          size: 10,
          sort: "registerDate",
          isAsc: false,
          type: "EVENT",
        },
        label: "EVENT",
        exact: true,
        menu: true,
        permittedRoles: ["CAMPAIGN"],
        icon: <EventIcon />,
        breadCrumb: true,
        level: 2,
        extraProps: {},
      },
      {
        path: "/userContent",
        component: UserContent,
        label: "USER_CONTENT",
        queryParams: {
          page: 0,
          size: 10,
          isAsc: true,
          sort: "createDate",
        },
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["USER_CONTENT"],
        icon: <ForumIcon />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/customerInteractionDashboard",
        component: CustomerInteractionDashboard,
        label: "Etkileşim Paneli",
        exact: true,
        menu: true,
        permittedRoles: [
          "CUSTOMER",
          "THINGMANAGER",
          "POWER_USER",
          "ACCOUNTOWNER",
        ],
        icon: <Dashboard />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuCustomerInteractionDashboard",
      },
    ],
  },
  {
    path: "/announcement",
    component: Announcement,
    label: "ANNOUNCEMENT",
    queryParams: { page: 0, size: 10, isAsc: true, sort: "startDate" },
    exact: true,
    menu: true,
    permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
    permittedModules: ["MY_THING1", "MY_THINGS"],
    icon: <FontAwesomeIcon icon={faBullhorn} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/t04",
    //component: EmptyPage,
    label: "TASK_MANAGEMENT",
    exact: true,
    menu: true,
    permittedRoles: ["POWER_USER", "ACCOUNTOWNER", "THINGMANAGER", "OPERATOR"],
    permittedModules: ["TASK_MANAGEMENT"],
    icon: <FontAwesomeIcon icon={faTasks} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    childRoutes: [
      {
        path: "/solutions",
        component: Solutions,
        label: "SOLUTIONS",
        exact: true,
        queryParams: {
          page: 0,
          size: 10,
          sort: "notificationDate",
          isAsc: false,
        },
        menu: true,
        permittedRoles: [
          "POWER_USER",
          "ACCOUNTOWNER",
          "THINGMANAGER",
          "OPERATOR",
        ],
        permittedModules: ["SERVICE_OPERATION"],
        icon: <FontAwesomeIcon icon={faWrench} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/calendar",
        component: Calendar,
        label: "CALENDAR",
        exact: true,
        menu: true,
        permittedRoles: [
          "POWER_USER",
          "ACCOUNTOWNER",
          "THINGMANAGER",
          "OPERATOR",
        ],
        permittedModules: ["TASK_MANAGEMENT"],
        icon: <FontAwesomeIcon icon={faCalendar} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/todo",
        component: Todo,
        label: "TODO",
        exact: true,
        menu: true,
        permittedRoles: [
          "POWER_USER",
          "ACCOUNTOWNER",
          "THINGMANAGER",
          "OPERATOR",
        ],
        permittedModules: ["TASK_MANAGEMENT"],
        icon: <FontAwesomeIcon icon={faStickyNote} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/tasks",
        component: Tasks,
        label: "TASKS",
        exact: true,
        menu: true,
        permittedRoles: [
          "POWER_USER",
          "ACCOUNTOWNER",
          "THINGMANAGER",
          "OPERATOR",
        ],
        permittedModules: ["TASK_MANAGEMENT"],
        icon: <FontAwesomeIcon icon={faTags} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
    ],
  },

  {
    path: "/t05",
    //component: EmptyPage,
    label: "AUTOMATION",
    exact: true,
    menu: true,
    permittedRoles: ["POWER_USER", "ACCOUNTOWNER", "THINGMANAGER"],
    permittedModules: ["TASK_MANAGEMENT", "QUEUE", "POLICY"],
    icon: <FontAwesomeIcon icon={faRobot} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    childRoutes: [
      {
        path: "/recurrence",
        component: Recurrence,
        label: "RECURRENCE",
        exact: true,
        queryParams: { page: 0, size: 10, sort: "description", isAsc: true },
        menu: true,
        permittedRoles: [
          "TASK_MANAGEMENT",
          "THINGMANAGER",
          "POWER_USER",
          "ACCOUNTOWNER",
        ],
        icon: <FontAwesomeIcon icon={faRecycle} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/queue",
        component: Queue,
        label: "QUEUE",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "orderNo", isAsc: true },
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["QUEUE"],
        icon: <FontAwesomeIcon icon={faTrain} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/policy",
        component: Policy,
        label: "POLICY",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "name", isAsc: true },
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["POLICY"],
        icon: <FontAwesomeIcon icon={faAtom} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
    ],
  },

  {
    path: "/t06",
    //component: EmptyPage,
    label: "SAYIM",
    exact: true,
    menu: true,
    permittedRoles: ["PATROL", "STOCK_TAKING"],
    icon: <FontAwesomeIcon icon={faRoute} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    childRoutes: [
      {
        path: "/stockTaking",
        component: StockTaking,
        label: "STOCK_TAKING",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "startDate", isAsc: false },
        permittedRoles: ["STOCK_TAKING"],
        icon: <FontAwesomeIcon icon={faWarehouse} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/patrolMonitor",
        component: Patrol,
        label: "PATROL",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "startDate", isAsc: false },
        permittedRoles: ["PATROL"],
        icon: <FontAwesomeIcon icon={faTv} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/patrolDef",
        component: PatrolDef,
        label: "PATROL_DEF",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "name", isAsc: true },
        permittedRoles: ["PATROL"],
        icon: <FontAwesomeIcon icon={faRoute} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
    ],
  },
  {
    path: "/customerInteractionMap",
    component: CustomerInteractionMap,
    label: "CUSTOMER_INTERACTION_MAP",
    exact: true,
    menu: false,
    breadCrumb: true,
    permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
    level: 1,
    extraProps: {},
  },
  {
    path: "/customerInteractionIndoorMap",
    component: CustomerInteractionIndoorMap,
    label: "CUSTOMER_INTERACTION_INDOOR_MAP",
    exact: true,
    menu: false,
    breadCrumb: true,
    permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
    level: 1,
    extraProps: {},
  },

  {
    path: "/t07",
    //component: EmptyPage,
    label: "STATISTICS",
    exact: true,
    menu: true,
    permittedRoles: ["POWER_USER", "ACCOUNTOWNER", "THINGMANAGER"],
    permittedModules: ["SERVICE_OPERATION"],
    icon: <FontAwesomeIcon icon={faCloud} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    childRoutes: [
      {
        path: "/solutionStats",
        component: SolutionStats,
        label: "STATISTICS_SOLUTION",
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER", "THINGMANAGER"],
        permittedModules: ["SERVICE_OPERATION"],
        icon: <FontAwesomeIcon icon={faChartLine} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      // {
      //   path: "/statsPanel",
      //   component: StatsPanel,
      //   label: "STATISTICS_PANEL",
      //   exact: true,
      //   menu: true,
      //   permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
      //   icon: <FontAwesomeIcon icon={faChartArea} />,
      //   breadCrumb: true,
      //   level: 1,
      //   extraProps: {},
      // },
      {
        path: "/missingData",
        component: MissingData,
        label: "MISSING_DATA",
        exact: true,
        menu: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["SERVICE_OPERATION"],
        icon: <FontAwesomeIcon icon={faBinoculars} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
    ],
  },
  {
    path: "/inbox",
    component: Inbox,
    label: "INBOX",
    exact: true,
    menu: false,
    permittedRoles: [
      "NO_ACCOUNT",
      "BENEFICIARY",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
      "ACCOUNTOWNER",
    ],
    icon: <List />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/inbox/detail/:id",
    component: InboxDetail,
    label: "INBOX_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: [
      "NO_ACCOUNT",
      "BENEFICIARY",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
      "ACCOUNTOWNER",
    ],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/recurrence/detail/:id",
    component: RecurrenceDetail,
    label: "RECURRENCE_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/todo/detail/:id",
    component: TodoDetail,
    label: "TODO_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["OPERATOR", "THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/patrolDef/detail/:id",
    component: PatrolDefDetail,
    label: "PATROL_DEF_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["PATROL"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/patrolDetail/:id",
    component: PatrolDetail,
    label: "PATROL_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["PATROL"],
    icon: <FontAwesomeIcon icon={faTv} />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/t08",
    //component: EmptyPage,
    label: "SLA",
    exact: true,
    menu: true,
    permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
    permittedModules: ["SLA"],
    icon: <FontAwesomeIcon icon={faHandshake} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    childRoutes: [
      {
        path: "/slaContract",
        component: SlaContract,
        label: "SLA_CONTRACT",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "name", isAsc: true },
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["SLA"],
        icon: <FontAwesomeIcon icon={faHandshake} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/issueSla",
        component: IssueSla,
        label: "SLA_ISSUE",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "name", isAsc: true },
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["SLA"],
        icon: <FontAwesomeIcon icon={faEdit} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/monitorSla",
        component: SlaContractMonitor,
        label: "SLA_MONITOR",
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["SLA"],
        icon: <FontAwesomeIcon icon={faTv} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/slaIssues",
        component: SlaIssuesList,
        label: "ISSUES",
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["SLA"],
        icon: <FontAwesomeIcon icon={faExclamationCircle} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        queryParams: {
          page: 0,
          size: 10,
          isAsc: false,
          sort: "notificationDate",
          accountId: localStorage.accountId,
        },
      },
    ],
  },
  {
    path: "/monitorSla/:id",
    component: SlaContractMonitor,
    label: "SLA_MONITOR",
    exact: true,
    menu: false,
    permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
    permittedModules: ["SLA"],
    icon: <FontAwesomeIcon icon={faTv} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  // {
  //   path: "/kpi",
  //   component: Kpi,
  //   label: "KPI",
  //   exact: true,
  //   menu: true,
  //   queryParams: { page: 0, size: 10, sort: "name", isAsc: true },
  //   permittedRoles: ["SLA"],
  //   icon: <TrendingUpIcon />,
  //   breadCrumb: true,
  //   level: 1,
  //   extraProps: {},
  // },
  {
    path: "/t09",
    //component: EmptyPage,
    label: "ACCOUNT_MANAGEMENT",
    exact: true,
    menu: true,
    permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
    icon: <FontAwesomeIcon icon={faSitemap} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    id: "menuAccountManagement",
    childRoutes: [
      {
        path: "/account-details",
        component: AccountDetails,
        label: "ACCOUNT_DETAILS",
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        icon: <AccountBox />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuAccountDetails",
      },
      {
        path: "/accountInteraction",
        component: AccountInteractionPage,
        label: "ACCOUNT_INTERACTION_PAGE",
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        icon: <Ballot />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuAccountInteraction",
      },
      {
        path: "/accountNotifications",
        component: AccountNotifications,
        label: "ACCOUNT_NOTIFICATIONS",
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        icon: <Repeat />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/messageTemplateTargets",
        component: MessageTemplateTargets,
        label: "MESSAGE_TEMPLATE_TARGETS",
        queryParams: { page: 0, size: 10, isAsc: true, sort: "id" },
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        icon: <ContactMailOutlinedIcon />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/accountUsers",
        component: AccountUsers,
        label: "ACCOUNT_USER_LIST",
        queryParams: { page: 0, size: 10, isAsc: false, sort: "id" },
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        icon: <FontAwesomeIcon icon={faUsers} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/userGroup",
        component: UserGroup,
        label: "USER_GROUP",
        queryParams: { page: 0, size: 10, isAsc: true, sort: "name" },
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["TASK_MANAGEMENT"],
        icon: <FontAwesomeIcon icon={faUsers} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/accountUserInvitation",
        component: AccountUserInvitation,
        label: "ACCOUNT_INVITATION",
        queryParams: {
          page: 0,
          size: 10,
          isAsc: false,
          sort: "invitationDate",
        },
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        icon: <FontAwesomeIcon icon={faEnvelopeOpenText} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      //  Hesap, etkileşim sayfası, bildirimler olarak bölündü
      //  {
      //   path: "/accountPreferences",
      //   component: AccountPreferences,
      //   label: "Hesap Seçenekleri",
      //   exact: true,
      //   menu: true,
      //   permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
      //   icon: <FontAwesomeIcon icon={faWrench} />,
      //   breadCrumb: true,
      //   level: 1,
      //   extraProps: {},
      //   id: "menuInteractionPageOptions",
      // },
      {
        path: "/accountSlaLevels",
        component: AccountSLALevels,
        label: "Varsayılan SLA Seviyeleri",
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["SLA"],
        icon: <FontAwesomeIcon icon={faWrench} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },

      {
        path: "/organization",
        component: Organization,
        label: "ORGANIZATION",
        exact: true,
        menu: true,
        //queryParams: { page: 0, size: 10, sort: "name", isAsc: true },
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        forbiddenRoles: ["FREE"],
        icon: <FontAwesomeIcon icon={faSitemap} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/profile",
        component: Profile,
        label: "PROFIL",
        exact: true,
        menu: false,
        breadCrumb: true,
        permittedRoles: [
          "NO_ACCOUNT",
          "BENEFICIARY",
          "OPERATOR",
          "THINGMANAGER",
          "POWER_USER",
          "ACCOUNTOWNER",
        ],
        icon: <FontAwesomeIcon icon={faUserAlt} />,
        level: 1,
        extraProps: {},
      },
      {
        path: "/storageStat",
        component: StorageStat,
        label: "STORAGE_STATUS",
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        icon: <FontAwesomeIcon icon={faCloud} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/proxy",
        component: Proxy,
        label: "Vekalet",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "id", isAsc: false },
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["TASK_MANAGEMENT"],
        icon: <SupervisorAccountIcon />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/exportData",
        component: AccountExportData,
        label: "ACCOUNT_EXPORT_DATA",
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        icon: <SystemUpdateAltIcon />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
    ],
  },
  {
    path: "/subscriptions",
    component: Subscriptions,
    label: "SUBSCRIPTION",
    exact: true,
    menu: true,
    permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
    icon: <FontAwesomeIcon icon={faWallet} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/project",
    component: Project,
    label: "PROJECT",
    exact: true,
    menu: true,
    permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
    permittedModules: ["PROJECT"],
    queryParams: { page: 0, size: 10, isAsc: true, sort: "startDate" },
    icon: <DonutLargeIcon />,
  },
  {
    path: "/trial",
    component: Trial,
    label: "TRIAL",
    exact: true,
    menu: false,
    permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
    icon: <FontAwesomeIcon icon={faWallet} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/project/detail/:id",
    component: ProjectDetail,
    label: "PROJECT_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
    permittedModules: ["PROJECT"],
    breadCrumb: true,
    level: 2,
  },
  {
    path: "/iyzicoCallback",
    component: IyzicoCallback,
    label: "Üyelik Sonuç",
    exact: true,
    menu: false,
    permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
    icon: <FontAwesomeIcon icon={faWallet} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/issueClass",
    component: IssueClass,
    label: "ISSUE_CLASS",
    exact: true,
    queryParams: { page: 0, size: 10, sort: "className", isAsc: true },
    menu: false, //FIXME
    permittedRoles: ["ACCOUNTOWNER", "OPERATOR", "THINGMANAGER", "POWER_USER"],
    icon: <FontAwesomeIcon icon={faSitemap} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/t10",
    //component: EmptyPage,
    label: "CUSTOMER_PORTAL",
    exact: true,
    menu: true,
    permittedRoles: ["CUSTOMER"],
    icon: <FontAwesomeIcon icon={faShoppingBasket} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    id: "menuCustomerPortal",
    childRoutes: [
      {
        path: "/customers",
        component: Customers,
        label: "CUSTOMERS",
        exact: true,
        queryParams: {
          page: 0,
          size: 10,
          sort: "membershipDate",
          isAsc: false,
        },
        menu: true,
        permittedRoles: ["CUSTOMER"],
        icon: <FontAwesomeIcon icon={faShoppingBasket} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/customerMessages",
        component: CustomerMessage,
        label: "CUSTOMER_MESSAGE",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "name", isAsc: false },
        permittedRoles: ["CUSTOMER"],
        icon: <Web />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuCustomerMessages",
      },
      {
        path: "/customerList",
        component: CustomerList,
        label: "CUSTOMER_LIST",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "name", isAsc: false },
        permittedRoles: ["CUSTOMER"],
        icon: <List />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/coupon",
        component: Coupon,
        label: "Kupon / Özel Teklif",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "startDate", isAsc: false },
        permittedRoles: ["CUSTOMER"],
        permittedModules: ["COUPON"],
        icon: <List />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuCoupon",
      },
      {
        path: "/useCoupon",
        component: UseCoupon,
        label: "USE_COUPON",
        exact: true,
        menu: true,
        permittedRoles: ["CAMPAIGN"],
        permittedModules: ["COUPON"],
        icon: <ConfirmationNumberIcon />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
    ],
  },
  {
    path: "/customer/detail/:id",
    component: CustomerDetail,
    label: "CUSTOMER_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["CUSTOMER"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/customerMessage/detail/:id",
    component: CustomerMessageDetail,
    label: "CUSTOMER_MESSAGE_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["CUSTOMER"],
    icon: <FontAwesomeIcon icon={faShoppingBasket} />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/customerListDetail/:id",
    component: CustomerListDetail,
    label: "CUSTOMER_LIST_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["CUSTOMER"],
    icon: <FontAwesomeIcon icon={faShoppingBasket} />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/coupon/detail/:id",
    component: CouponDetail,
    label: "COUPON_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["CUSTOMER"],
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/t16",
    label: "SURVEY",
    exact: true,
    menu: true,
    permittedRoles: ["SURVEY", "SRVY_SIMPLE"],
    icon: <ThingsSurveyIcon />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    childRoutes: [
      {
        path: "/survey",
        component: Surveys,
        label: "SURVEY_DEFINITIONS",
        queryParams: { page: 0, size: 10, isAsc: true, sort: "name" },
        exact: true,
        menu: true,
        permittedRoles: ["SURVEY", "SRVY_SIMPLE"],
        icon: <MenuBook />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/surveyAnswers",
        component: SurveyAnswerList,
        label: "ANSWERS",
        exact: true,
        menu: true,
        permittedRoles: ["SURVEY", "SRVY_SIMPLE"],
        icon: <List />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
    ],
  },
  {
    path: "/t11",
    //component: ,
    label: "EVENT",
    exact: true,
    menu: true,
    permittedRoles: ["CAMPAIGN"],
    icon: <EventIcon />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    id: "menuEvent",
    childRoutes: [
      {
        path: "/campaign",
        component: Campaign,
        label: "CAMPAIGN",
        queryParams: { page: 0, size: 10, isAsc: true, sort: "name" },
        exact: true,
        menu: true,
        permittedRoles: ["CAMPAIGN"],
        icon: <ThingsCampaignIcon />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuCampaign",
      },
      {
        path: "/event",
        component: Event,
        label: "EVENT",
        queryParams: { page: 0, size: 10, isAsc: true, sort: "name" },
        exact: true,
        menu: true,
        permittedRoles: ["CAMPAIGN"],
        icon: <EventIcon />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
    ],
  },
  {
    path: "/t12",
    //component: EmptyPage,
    label: "PREFERENCES",
    exact: true,
    menu: true,
    permittedRoles: ["ACCOUNTOWNER", "POWER_USER"],
    icon: <FontAwesomeIcon icon={faSitemap} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    id: "menuPreferences",
    childRoutes: [
      {
        path: "/thingClass",
        component: ThingClass,
        label:
          THINGS_PACKAGE === "thingsAndFeedbacks"
            ? "THING_CLASS"
            : "VARLIK_TIPLERI",
        exact: true,
        queryParams: { page: 0, size: 10, sort: "count", isAsc: false },
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        icon: <FontAwesomeIcon icon={faSitemap} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
        id: "menuThingClass",
      },
      // {
      //   path: "/issueClass",
      //   component: IssueClass,
      //   label: "ISSUE_CLASS",
      //   exact: true,
      //   queryParams: { page: 0, size: 10, sort: "className", isAsc: true },
      //   menu: true,
      //   permittedRoles: ["ACCOUNTOWNER", "OPERATOR", "THINGMANAGER"],
      //   icon: <FontAwesomeIcon icon={faSitemap} />,
      //   breadCrumb: true,
      //   level: 1,
      //   extraProps: {},
      // },
      {
        path: "/serviceOperationClass",
        component: ServiceOperationClass,
        label: "SERVICE_OPERATION_CLASS",
        exact: true,
        queryParams: { page: 0, size: 10, sort: "className", isAsc: true },
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["SERVICE_OPERATION"],
        icon: <FontAwesomeIcon icon={faWrench} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },

      //BAKIM GÖREVLERİ
      {
        path: "/vendor",
        component: Vendor,
        label: "VENDORS",
        exact: true,
        queryParams: { page: 0, size: 10, sort: "name", isAsc: false },
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["THINGS_2_CARE"],
        icon: <FontAwesomeIcon icon={faBuilding} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/location",
        component: Location,
        label: "LOCATION",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "name", isAsc: true },
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        icon: <FontAwesomeIcon icon={faGlobe} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      //İLETİ ŞABLONLARI
      {
        path: "/qrTemplate",
        component: QRTemplate,
        label: "QR_TEMPLATE",
        exact: true,
        menu: true,
        permittedRoles: [
          "OPERATOR",
          "THINGMANAGER",
          "POWER_USER",
          "ACCOUNTOWNER",
        ],
        icon: <FontAwesomeIcon icon={faTicketAlt} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/stock",
        component: Stock,
        label: "STOCK",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "name", isAsc: true },
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["SERVICE_OPERATION"],
        icon: <FontAwesomeIcon icon={faWarehouse} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/SLA-calendar",
        component: SlaCalendar,
        label: "SLA_CALENDAR",
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["SLA"],
        icon: <FontAwesomeIcon icon={faHandshake} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/relationType",
        component: RelationType,
        label: "RELATION_TYPE",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "nameSource", isAsc: true },
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        forbiddenRoles: ["FREE"],
        icon: <FontAwesomeIcon icon={faLink} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/schedulePlan",
        component: SchedulePlan,
        label: "SCHEDULE_PLAN",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "name", isAsc: true },
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["POLICY", "QUEUE"],
        icon: <FontAwesomeIcon icon={faCalendarWeek} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/periodicReport",
        component: PeriodicReport,
        label: "PERIODIC_REPORT",
        queryParams: { page: 0, size: 10, sort: "name", isAsc: true },
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["TASK_MANAGEMENT"],
        icon: <FontAwesomeIcon icon={faRecycle} />,
        breadCrumb: true,
        level: 1,
      },
      {
        path: "/legalText",
        component: LegalText,
        label: "LEGAL_TEXT",
        queryParams: { page: 0, size: 10, isAsc: true, sort: "name" },
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        icon: <Assignment />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/messageTemplates",
        component: MessageTemplate,
        label: "MESSAGE_TEMPLATES",
        queryParams: { page: 0, size: 10, isAsc: true, sort: "actionType" },
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["MESSAGE_TEMPLATE"],
        icon: <FontAwesomeIcon icon={faParagraph} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/teamRoles",
        component: TeamRoles,
        label: "TEAM_ROLES",
        queryParams: { page: 0, size: 10, isAsc: true, sort: "name" },
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["CAMPAIGN"],
        icon: <FontAwesomeIcon icon={faParagraph} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/surveyQuestionLibrary",
        component: SurveyQuestionLibrary,
        label: "SURVEY_QUESTION_LIBRARY",
        exact: true,
        menu: true,
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        permittedModules: ["SURVEY", "SRVY_SIMPLE"],
        icon: <MenuBookIcon />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/journalType",
        component: JournalType,
        label: "JOURNAL_TYPE",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "name", isAsc: true },
        permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
        forbiddenRoles: ["FREE"],
        permittedModules: ["JOURNAL"],
        icon: <FontAwesomeIcon icon={faLink} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
    ],
  },
  {
    path: "/t15",
    //component: EmptyPage,
    label: "SYSTEM_LOG",
    exact: true,
    menu: true,
    permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
    icon: <FontAwesomeIcon icon={faHistory} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    childRoutes: [
      {
        path: "/messages",
        component: Messages,
        label: "MESSAGES",
        exact: true,
        menu: true,
        queryParams: { page: 0, size: 10, sort: "id", isAsc: false },
        permittedRoles: [
          "ACCOUNTOWNER",
          "OPERATOR",
          "THINGMANAGER",
          "POWER_USER",
        ],
        icon: <FontAwesomeIcon icon={faEnvelope} />,
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
    ],
  },
  {
    path: "/t13",
    //component: EmptyPage,
    label: "STATISTICS_ISSUE",
    exact: true,
    menu: true,
    permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
    icon: <FontAwesomeIcon icon={faChartLine} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    childRoutes: [
      {
        path: "/stats/issueCountStat",
        component: IssueCountStat,
        label: "ISSUE_STAT_COUNT",
        exact: true,
        menu: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/stats/issueCountByThingClass",
        component: IssueCountByThingClass,
        label: isTNF(
          "ISSUE_STAT_BY_THINGCLASS",
          "ISSUE_STAT_BY_THINGCLASS_T2C"
        ),
        exact: true,
        menu: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/stats/issueClassCountByDate",
        component: IssueClassCountByDate,
        label: isTNF("ISSUE_STAT_BY_DATE", "ISSUE_STAT_BY_DATE_T2C"),
        exact: true,
        menu: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/stats/issueCountByVendor",
        component: IssueCountByVendor,
        label: "ISSUE_STAT_BY_VENDOR",
        exact: true,
        menu: true,
        permittedRoles: ["THINGS_2_CARE"],
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/stats/issueCountByLocation",
        component: IssueCountByLocation,
        label: "ISSUE_STAT_BY_LOCATION",
        exact: true,
        menu: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/stats/issueCountByOrganization",
        component: IssueCountByOrganization,
        label: "ISSUE_STAT_BY_ORGANIZATION",
        exact: true,
        menu: true,
        permittedRoles: ["THINGS_2_CARE"],
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/stats/issueCountByUser",
        component: IssueCountByUser,
        label: "ISSUE_STAT_BY_OWNER",
        exact: true,
        menu: true,
        permittedRoles: ["THINGS_2_CARE"],
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/stats/openIssueOpenTimeByThingClass",
        component: OpenIssueOpenTimeByThingClass,
        label: isTNF("ISSUE_STAT_BY_OPEN_TIME", "ISSUE_STAT_BY_OPEN_TIME_T2C"),
        exact: true,
        menu: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/stats/ClosedIssueOpenTimeByThingClass",
        component: ClosedIssueOpenTimeByThingClass,
        label: isTNF(
          "ISSUE_STAT_BY_CLOSE_TIME",
          "ISSUE_STAT_BY_CLOSE_TIME_T2C"
        ),
        exact: true,
        menu: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
    ],
  },
  {
    path: "/t14",
    label: isTNF("STATISTICS_THINGS", "STATISTICS_THINGS_T2C"),
    exact: true,
    menu: true,
    permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
    icon: <FontAwesomeIcon icon={faChartLine} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
    childRoutes: [
      {
        path: "/stats/thingCountStat",
        component: ThingCountStat,
        label: isTNF("THING_STAT_BY_COUNT", "THING_STAT_BY_COUNT_T2C"),
        exact: true,
        menu: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/stats/thingCountByLocation",
        component: ThingCountByLocation,
        label: "THING_STAT_BY_LOCATION",
        exact: true,
        menu: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/stats/thingCountByOrganization",
        component: ThingCountByOrganization,
        label: "THING_STAT_BY_ORGANIZATION",
        exact: true,
        menu: true,
        permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
      {
        path: "/stats/thingCountByVendor",
        component: ThingCountByVendor,
        label: "THING_STAT_BY_VENDOR",
        exact: true,
        menu: true,
        permittedRoles: ["THINGS_2_CARE"],
        breadCrumb: true,
        level: 1,
        extraProps: {},
      },
    ],
  },
  {
    path: "/tutorials",
    component: Tutorials,
    label: "TUTORIALS",
    exact: true,
    menu: true,
    permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
    icon: <Help />,
  },
  {
    path: "/tutorial/:code",
    component: TutorialViewer,
    label: "TUTORIAL_VIEWER",
    exact: true,
    menu: false,
    permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/legalText/detail/:id",
    component: LegalTextDetail,
    label: "LEGAL_TEXT_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: [
      "NO_ACCOUNT",
      "BENEFICIARY",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
      "ACCOUNTOWNER",
    ],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/messageTemplateDetail/:id",
    component: MessageTemplateDetail,
    label: "MESSAGE_TEMPLATE_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
    icon: <FontAwesomeIcon icon={faParagraph} />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/messageTemplateEmailEditor/:id",
    component: MessageTemplateEmailEditor,
    label: "MESSAGE_TEMPLATE_EMAIL_EDITOR",
    exact: true,
    menu: false,
    permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
    icon: <FontAwesomeIcon icon={faParagraph} />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/teamRoleDetail/:id",
    component: TeamRolesDetail,
    label: "TEAM_ROLE_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
    icon: <FontAwesomeIcon icon={faParagraph} />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/accountIncentiveScreen",
    component: AccountIncentiveScreen,
    label: "ACCOUNT_INCENTIVE_SCREEN",
    exact: true,
    menu: false,
    permittedRoles: [
      "NO_ACCOUNT",
      "BENEFICIARY",
      "ACCOUNTOWNER",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
    ],
    icon: <FontAwesomeIcon icon={faQrcode} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },

  {
    path: "/forbidden",
    component: ForbiddenPage,
    label: "ForbiddenPage",
    exact: true,
    menu: false,
    breadCrumb: true,
    permittedRoles: [
      "NO_ACCOUNT",
      "BENEFICIARY",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
      "ACCOUNTOWNER",
    ],
    level: 1,
    extraProps: {},
  },
  {
    path: "/updateProfile",
    component: FaProfileUpdate,
    label: "PROFILE_UPDATE",
    exact: true,
    menu: false,
    breadCrumb: true,
    permittedRoles: [
      "NO_ACCOUNT",
      "BENEFICIARY",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
      "ACCOUNTOWNER",
    ],
    level: 1,
    extraProps: {},
  },

  {
    path: "/labelDesigner",
    component: LabelDesigner,
    label: "LABEL_DESIGNER",
    exact: true,
    menu: false,
    permittedRoles: ["ACCOUNTOWNER", "OPERATOR", "THINGMANAGER", "POWER_USER"],
    icon: <FontAwesomeIcon icon={faTicketAlt} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/labelDesignerSample",
    component: LabelDesignerSample,
    label: "LABEL_DESIGNER",
    exact: true,
    menu: false,
    permittedRoles: ["ACCOUNTOWNER", "OPERATOR", "THINGMANAGER", "POWER_USER"],
    icon: <FontAwesomeIcon icon={faTicketAlt} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },

  {
    path: "/thingsFood",
    component: ThingsFood,
    label: "THINGS_FOOD",
    queryParams: { page: 0, size: 10, isAsc: false, sort: "createDate" },
    exact: true,
    menu: true,
    permittedRoles: ["FOOD"],
    icon: <FontAwesomeIcon icon={faQrcode} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },

  {
    path: "/productDashboard/:id",
    component: ProductDashboard,
    label: "Ürün Paneli",
    exact: true,
    menu: false,
    permittedRoles: [
      "BENEFICIARY",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
      "ACCOUNTOWNER",
      "FOOD",
    ],
    icon: <Dashboard />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/policy/detail/:id",
    component: PolicyDetail,
    label: "POLICY_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["POLICY"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/queue/detail/:id",
    component: QueueDetail,
    label: "QUEUE_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["QUEUE"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/SLA-calendar/edit/:id",
    component: SlaCalendarEdit,
    label: "SLA_CALENDAR_EDIT",
    exact: true,
    menu: false,
    permittedRoles: ["SLA"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/slaContract/detail/:id",
    component: SlaContractDetail,
    label: "SLA_CONTRACT_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["SLA"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/things/detail",
    component: ThingsDetail,
    label: "LIST_EKRAN_DETAY",
    exact: true,
    menu: false,
    permittedRoles: ["ACCOUNTOWNER"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/thingDetail/:id",
    component: ThingDetail,
    label: "LIST_EKRAN_DETAY",
    exact: true,
    menu: false,
    permittedRoles: [
      "BENEFICIARY",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
      "ACCOUNTOWNER",
      "FOOD",
    ],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/thingRelationExplorer/:id",
    component: ThingRelationExplorer,
    label: "THING_RELATION_EXPLORER",
    exact: true,
    menu: false,
    permittedRoles: [
      "BENEFICIARY",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
      "ACCOUNTOWNER",
      "FOOD",
    ],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/thingDetailFood/:id",
    component: ThingDetailFood,
    label: "LIST_EKRAN_DETAY",
    exact: true,
    menu: false,
    permittedRoles: ["FOOD"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/mapIndoor",
    component: ThingsIndoorMap,
    label: "Binalar/İç Mekanlar",
    exact: true,
    menu: false,
    permittedRoles: [
      "BENEFICIARY",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
      "ACCOUNTOWNER",
      "FOOD",
    ],
    icon: <FontAwesomeIcon icon={faMap} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/suggestions/detail/:id",
    component: SuggestionDetail,
    label: "SUGGESTION_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["OPERATOR", "THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/like/detail/:id",
    component: LikeDetail,
    label: "LIKE_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["OPERATOR", "THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/issueClassDetail/:id",
    component: IssueClassDetail,
    label: "ISSUE_CLASS_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["ACCOUNTOWNER", "OPERATOR", "THINGMANAGER", "POWER_USER"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/serviceOperationClassDetail/:id",
    component: ServiceOperationClassDetail,
    label: "SERVICE_OPERATION_CLASS_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/thingClassDetail/:id",
    component: ThingClassDetail,
    label: isTNF("THING_CLASS_DETAIL", "THING_CLASS_DETAIL_T2C"),
    exact: true,
    menu: false,
    permittedRoles: ["THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/additionalFields/edit/:id",
    component: AdditionalFieldsEdit,
    label: "ADDITIONAL_FIELDS_EDIT",
    exact: true,
    menu: false,
    permittedRoles: ["ACCOUNTOWNER", "OPERATOR", "THINGMANAGER", "POWER_USER"],
    icon: <List />,
    breadCrumb: true,
    level: 3,
    extraProps: {},
  },

  {
    path: "/issuesFood",
    component: IssuesFood,
    label: "SORUNLAR_FOOD",
    menu: true,
    queryParams: {
      page: 0,
      size: 10,
      isAsc: false,
      sort: "notificationDate",
      accountId: localStorage.accountId,
    },
    exact: true,
    menu: true,
    permittedRoles: ["FOOD"],
    icon: <FontAwesomeIcon icon={faExclamationCircle} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/thingChanges/detail/:id",
    component: ThingChangeDetail,
    label: "THING_CHANGE_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["OPERATOR", "THINGMANAGER", "POWER_USER", "ACCOUNTOWNER"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/issues/detail/:id",
    component: IssueDetail,
    label: "SORUN_DETAY",
    exact: true,
    menu: false,
    permittedRoles: [
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
      "ACCOUNTOWNER",
      "BENEFICIARY",
    ],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/userContent/detail/:id",
    component: UserContentDetail,
    label: "USER_CONTENT_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["ACCOUNTOWNER", "POWER_USER"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/issues/detailFood/:id",
    component: IssueDetailFood,
    label: "SORUN_DETAY",
    exact: true,
    menu: false,
    permittedRoles: ["ACCOUNTOWNER", "FOOD"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  // {
  //   path: "/inspections",
  //   component: Inspections,
  //   label: "ISSUE_INSPECTIONS",
  //   exact: true,
  //   queryParams: { page: 0, size: 10, sort: "notificationDate", isAsc: false },
  //   menu: true,
  //   permittedRoles: ["ACCOUNTOWNER"],
  //   icon: <FontAwesomeIcon icon={faGlobeAsia} />,
  //   breadCrumb: true,
  //   level: 1,
  //   extraProps: {},
  // },

  {
    path: "/solutions/detail/:id",
    component: SolutionDetail,
    label: "SOLUTION_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["ACCOUNTOWNER", "OPERATOR", "THINGMANAGER", "POWER_USER"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },

  {
    path: "/campagins",
    component: Campaigns,
    label: "PAKETLER",
    exact: true,
    menu: false,
    permittedRoles: ["ACCOUNTOWNER"],
    icon: <FontAwesomeIcon icon={faShoppingBasket} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },

  {
    path: "/checkout/:id",
    component: Checkout,
    label: "ODEME",
    exact: true,
    menu: false,
    permittedRoles: ["ACCOUNTOWNER"],
    icon: <FontAwesomeIcon icon={faCreditCard} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  // {
  //   path: "/country",
  //   component: Country,
  //   label: "Paket Ekle",
  //   exact: true,
  //   menu: true,
  //   permittedRoles: ["ACCOUNTOWNER"],
  //   icon: <FontAwesomeIcon icon={faGlobeAsia} />,
  //   breadCrumb: true,
  //   level: 1,
  //   extraProps: {},
  // },
  // {
  //   path: "/testpage",
  //   component: Deneme,
  //   label: "Test Page",
  //   exact: true,
  //   menu: true,
  //   permittedRoles: ["ACCOUNTOWNER"],
  //   icon: <FontAwesomeIcon icon={faGlobeAsia} />,
  //   breadCrumb: true,
  //   level: 1,
  //   extraProps: {},
  // },

  {
    path: "/homepage",
    component: Homepage,
    label: "Homepage",
    exact: true,
    menu: false,
    permittedRoles: [
      "NO_ACCOUNT",
      "ACCOUNTOWNER",
      "BENEFICIARY",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
    ],
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },

  {
    path: "/stockTaking/detail/:id",
    component: StockTakingDetail,
    label: "STOCK_TAKING_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["STOCK_TAKING"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/location/detail/:id",
    component: LocationDetail,
    label: "LOCATION_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["POWER_USER", "ACCOUNTOWNER"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/periodicReport/detail/:id",
    component: PeriodicReportDetail,
    label: "PERIODIC_REPORT_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: [
      "CUSTOMER",
      "ACCOUNTOWNER",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
    ],
    icon: <FontAwesomeIcon icon={faShoppingBasket} />,
    breadCrumb: true,
    level: 2,
  },
  {
    path: "/survey/detail/:id",
    component: SurveyDetail,
    label: "SURVEY_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: [
      "CUSTOMER",
      "ACCOUNTOWNER",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
    ],
    breadCrumb: true,
    level: 2,
  },
  {
    path: "/campaign/detail/:id",
    component: CampaignDetail,
    label: "CAMPAIGN_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: [
      "CUSTOMER",
      "ACCOUNTOWNER",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
    ],
    breadCrumb: true,
    level: 2,
  },
  {
    path: "/event/detail/:id",
    component: CampaignDetail,
    label: "EVENT_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: ["CAMPAIGN"],
    breadCrumb: true,
    level: 2,
  },
  {
    path: "/campaign/customers/:id",
    component: CampaignCustomers,
    label: "CAMPAIGN_CUSTOMERS",
    exact: true,
    menu: false,
    permittedRoles: ["CAMPAIGN"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/survey/analysis/:id",
    component: SurveyAnalysis,
    label: "SURVEY_ANALYSIS",
    exact: true,
    menu: false,
    permittedRoles: ["SURVEY", "SRVY_SIMPLE"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/survey/surveyAllAnswers/:id",
    component: SurveyAllAnswers,
    label: "SURVEY_ALL_ANSWERS",
    exact: true,
    menu: false,
    permittedRoles: ["SURVEY", "SRVY_SIMPLE"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/survey/answers/:id",
    component: SurveyAnswerList,
    label: "SURVEY_ANSWERS",
    exact: true,
    menu: false,
    permittedRoles: ["SURVEY", "SRVY_SIMPLE"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },

  {
    path: "/accessLog/detail/:id",
    component: AccessLogDetail,
    label: "ACCESS_LOG_DETAIL",
    exact: true,
    menu: false,
    permittedRoles: [
      "CUSTOMER",
      "ACCOUNTOWNER",
      "OPERATOR",
      "THINGMANAGER",
      "POWER_USER",
    ],
    breadCrumb: true,
    level: 2,
  },

  {
    path: "/sync/ldapSync",
    component: LdapSync,
    label: "Ldap Sync",
    exact: true,
    menu: false,
    permittedRoles: ["ACCOUNTOWNER", "THINGMANAGER", "POWER_USER"],
    icon: <List />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },

  {
    path: "/bportal/homepage",
    component: BeneficiaryHomepage,
    label: "Karşılama Sayfası",
    exact: true,
    menu: true,
    permittedRoles: ["PUBLIC_BENEFICIARY"],
    icon: <FontAwesomeIcon icon={faHandshake} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/bportal/openIssues",
    component: OpenIssues,
    label: "Açık Sorunlar",
    exact: true,
    menu: true,
    permittedRoles: ["PUBLIC_BENEFICIARY"],
    icon: <FontAwesomeIcon icon={faExclamationCircle} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/bportal/permissions",
    component: MyPermissions,
    label: "İzinlerim",
    exact: true,
    menu: true,
    permittedRoles: ["PUBLIC_BENEFICIARY"],
    icon: <FontAwesomeIcon icon={faUserLock} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/bportal/customerInfo",
    component: CustomerInfo,
    label: "Kayıtlı Kişisel Bilgilerim",
    exact: true,
    menu: true,
    permittedRoles: ["PUBLIC_BENEFICIARY"],
    icon: <FontAwesomeIcon icon={faUser} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/bportal/CustomerThings",
    component: CustomerThings,
    label: "Ürün ve Hizmetlerim",
    exact: true,
    menu: true,
    permittedRoles: ["PUBLIC_BENEFICIARY"],
    icon: <FontAwesomeIcon icon={faBoxOpen} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/bportal/campaigns",
    component: MyCampaigns,
    label: "Kampanya ve Etkinlikler",
    exact: true,
    menu: true,
    permittedRoles: ["PUBLIC_BENEFICIARY"],
    icon: <ThingsCampaignIcon />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/bportal/interactions",
    component: MyInteractions,
    label: "Geçmiş Etkileşimlerim",
    exact: true,
    menu: true,
    permittedRoles: ["PUBLIC_BENEFICIARY"],
    icon: <FontAwesomeIcon icon={faHandPointUp} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
  {
    path: "/bportal/campaignDetail/:id/:accId",
    component: BeneficiaryPortalCampaignDetail,
    label: "Kampanya Detay",
    exact: true,
    menu: false,
    permittedRoles: ["PUBLIC_BENEFICIARY"],
    icon: <List />,
    breadCrumb: true,
    level: 2,
    extraProps: {},
  },
  {
    path: "/bportal/messages",
    component: BeneficiaryMessages,
    label: "Mesajlarım",
    exact: true,
    menu: true,
    queryParams: { page: 0, size: 10, isAsc: false, sort: "createDate" },
    permittedRoles: ["PUBLIC_BENEFICIARY"],
    icon: <FontAwesomeIcon icon={faEnvelope} />,
    breadCrumb: true,
    level: 1,
    extraProps: {},
  },
];

export const contextRoutes = handleContextRoutes(thingContextRoutes);

export const unAuthenticated = [
  {
    path: "/login",
    component: Login,
    label: "Login",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      requestUrl: AUT.login,
      fgPasswordPath: "/forgot-password",
      signUpPath: "/sign-up",
      align: "center",
      userPhoneNumberLogin: true,
      usernameLogin: true,
    },
  },
  {
    path: "/login-tf",
    component: LoginTF,
    label: "Login",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      requestUrl: AUT.login,
      fgPasswordPath: "/forgot-password",
      signUpPath: "/sign-up",
      align: "center",
      userPhoneNumberLogin: true,
      usernameLogin: true,
    },
  },
  {
    path: "/login-t2c",
    component: LoginT2C,
    label: "Login",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogoT2CDark,
      requestUrl: AUT.login,
      fgPasswordPath: "/forgot-password",
      signUpPath: "/sign-up-things2care",
      align: "center",
      userPhoneNumberLogin: true,
      usernameLogin: true,
    },
  },
  {
    path: "/sign-up",
    component: SignUp,
    label: "Sign Up",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/sign-up-things2care",
    component: SignUpThings2Care,
    label: "Sign Up",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogoT2CDark,
      loginPath: "/login",
    },
  },
  {
    path: "/invitationAcception/:invitationCode",
    component: InvitationAcceptionScreen,
    label: "InvitationAcception",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
      loginUrl: AUT.login,
    },
  },
  {
    path: "/confirmation",
    component: SmsAndEmailConfirmation,
    label: "Confirmation",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
      loginUrl: AUT.login,
    },
  },
  {
    path: "/change-password",
    component: ChangePassword,
    label: "Confirmation",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    label: "Forgot Password",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/email-verify/:confirmationCode",
    component: VerificationPage,
    exact: true,
    permittedRoles: [],
    icon: "",
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/user-invitation/verify/:confirmationCode",
    component: SignUp,
    label: "Sign Up",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/report/:tid",
    component: Report,
    label: "Report",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/q/:tid",
    component: Report,
    label: "Report",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      miniAppLogo: process.env.PUBLIC_URL + "/assets/icons/favicon-96x96.png",
      loginPath: "/login",
    },
  },
  {
    path: "/report/public/:tid",
    component: PublicInfo,
    label: "PublicInfo",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/report/issue/:tid",
    component: ReportIssue,
    label: "ReportIssue",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
      miniAppLogo: process.env.PUBLIC_URL + "/assets/icons/favicon-96x96.png",
    },
  },
  {
    path: "/report/like/:tid",
    component: ReportLike,
    label: "ReportLike",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
      miniAppLogo: process.env.PUBLIC_URL + "/assets/icons/favicon-96x96.png",
    },
  },
  {
    path: "/report/suggestion/:tid",
    component: ReportSuggestion,
    label: "ReportSuggestion",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
      miniAppLogo: process.env.PUBLIC_URL + "/assets/icons/favicon-96x96.png",
    },
  },
  {
    path: "/report/userContent/:tid",
    component: ReportUserContent,
    label: "ReportUserContent",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/report/customer/tid=:tid?",
    component: Customer,
    label: "Customer",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/report/survey/:id/:thingId?/:accountId",
    component: Survey,
    label: "Survey",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/report/surveyResult/:id",
    component: SurveyResult,
    label: "SurveyResult",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
      miniAppLogo: process.env.PUBLIC_URL + "/assets/icons/favicon-96x96.png",
    },
  },
  {
    path: "/report/registerCampaign/:id/:thingId/:accountId",
    component: RegisterCampaign,
    label: "RegisterCampaign",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
      miniAppLogo: process.env.PUBLIC_URL + "/assets/icons/favicon-96x96.png",
    },
  },
  {
    path: "/report/registerCampaignResult/:id",
    component: RegisterCampaignResult,
    label: "RegisterCampaignResult",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/report/getIssue/:id/:thingId/:accountId",
    component: PublicIssueStatus,
    label: "PublicIssueStatus",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/bportal/campaignStatus/:id/:campaignId/:accountId",
    component: CampaignStatus,
    label: "CampaignStatus",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/bportal/login",
    component: BeneficiaryLogin,
    label: "BeneficiaryLogin",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/bportal/loginLdap",
    component: BeneficiaryLoginLdap,
    label: "BeneficiaryLogin",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/bportal/accountSelection",
    component: BeneficiaryAccountSelection,
    label: "Hesap Seçim",
    exact: true,
    menu: false,
    permittedRoles: ["PUBLIC_BENEFICIARY"],
    icon: <List />,
    breadCrumb: true,
    level: 1,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
    },
  },
  {
    path: "/report/issueFood/:tid",
    component: ReportIssueFood,
    label: "ReportIssue",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/unsubscribe/:customerId/:accountId",
    component: Unsubscribe,
    label: "Unsubscribe",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
  {
    path: "/beneficiaryNoteReply/:id/:accountId",
    component: BeneficiaryNoteReply,
    label: "BeneficiaryNoteReply",
    exact: true,
    menu: false,
    permittedRoles: [],
    icon: "",
    breadCrumb: false,
    extraProps: {
      appLogo: THINGS_ENVIRONMENT.appLogo,
      loginPath: "/login",
    },
  },
];

export const privateRoutes = ["/", ...contextRoutes.map((route) => route.path)];
